.question-stem {
    margin-bottom:40px;

    .question-title {
        font-size:1.125rem;
        line-height:1.5;
        font-weight:600;
        color: #333333;
        margin-bottom:0.75rem;
    }
    .question-metadata {
        display:flex;
        align-items: center;
        gap:8px;
        font-size:0.75rem;
        line-height:1rem;
        color: #333333;

        .divider {
            display:inline-block;
                width:2px;
                height:1rem;
                background: #0000001F;
                margin: 0 4px;
                vertical-align: middle;
        }
    }

    .image {
        width:100%;
        img {
            width:100%;
            max-width:600px;
        }
    }
}


.question-part-group {
    .question-part-title {
        font-size:1rem;
        font-weight:600;
        line-height:1.5;
        margin: 0 0 1rem 0;
        color:#000;
    }
    fieldset {
        border:0;
        margin:0;
        padding:0;
    }
    .question-stem {
        font-size: 1rem;
        line-height:1.5;
        color: #333333;
        margin:0;
    }
    .question-instructions {
        font-size: 1rem;
        line-height:1.5;
        font-weight:600;
        color: #333333;
        margin-top:1rem;
        margin-bottom:0;
    }
}
.question-actions {
    padding:16px 0;

    button {
        margin:0;
    }
}
.response-container {
    width:100%;
    border-radius: 4px;
    background: #FFF;
    // mix-blend-mode: luminosity;
    box-shadow: 0px 1px 2px 0px rgba(84, 110, 122, 0.24), 0px 2px 4px 0px rgba(120, 144, 156, 0.24);
    .response-btn {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        width:100%;
        padding: 12px 24px;
        border-radius: 4px;
        border:0;
        background: grey;
        box-shadow: 0px 1px 2px 0px rgba(84, 110, 122, 0.24), 0px 2px 4px 0px rgba(120, 144, 156, 0.24);

        .title {
            font-size:1rem;
            font-weight:600;
            line-height:1.5;
            margin:0;
        }
        .icon {
            transition:transform 0.3s ease;
            transform: rotate(180deg);
        }
        &[aria-expanded=true] {
            .icon {
                transform: rotate(0deg);
            }
        }
    }

    .response {
        padding: 16px 24px;
    }

    &.incorrect {
        .response-btn {
            color: #910B08;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #DA100B;
        }
        .title {
            color: #910B08;
        }
    }
    &.correct {
        .response-btn {
            color: #388539;
            background:linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #4CAF50
        }
        .title {
            color: #388539;
        }
    }
}

.part-image {
    width:100%;
    max-width:100%;

    img {
        width:100%;
    max-width:100%;
    }
}

.labelling-activity {
    display:flex;
    flex-direction: column;
    gap:24px;
    .draggables {
        padding:12px;
        border:1px solid #96B6C6;
        border-radius:12px;
        // display:flex;
        // flex-direction:row;
        // gap:8px;
        // flex-wrap:wrap;
        // height:100%;
    }

    .drag-btn {
        // flex:none;
        border:1px solid #85C5DB;
        border-radius:8px;
        background:#d9edf7;
        min-height:40px;
        padding: 4px 12px;
        font-size:0.75rem;
        margin:4px;
        // width:100%;

        &:hover {
            box-shadow: 0 0 4px 2px #31708f;
            border-color: white !important;
            cursor: grab;
        }
    }

    .image-container {
        position:relative;
        height: fit-content;
        img {
            width:100%;
            max-width:100%;
        }

        .drop-areas {
            position:absolute;
            width:100%;
            height:100%;

            .drop-target {
                position:absolute;
                border:1px solid #96B6C6;
                border-radius:8px;
                background:#BFDFEB;
                min-height:30px;
                // max-width:100px;
                padding:4px;
                width:17%;
                    opacity:0.9;

                    .drag-btn {margin:0;width:100%;white-space: nowrap;text-overflow: ellipsis; overflow:hidden;min-width:0;}
            }
        }
    }

    @media (min-width:768px) {
        flex-direction: row;
        // align-items: flex-start;
        flex-wrap:wrap;
        .draggables {
            flex:1 0 calc(130px + 50px);
            // max-width:180px;
        }
        .drag-btn {
            padding: 4px 12px;
            font-size:0.825rem;
            // flex:0 0 130px;
            height:min-content;
            // width:max-content;
            min-width:130px;
        }
        .image-container {
            flex:4 1 calc(100% - 204px);
            min-width:400px;
            .drop-areas {
                .drop-target {
                    min-height:48px;
                    height:12%;
                    // max-width:130px;
                    width:17%;
                    .drag-btn {width:100%;height:100%;min-width:0;}
                }
            }
        }
    }
}