
#root {
    display: flex;
    //transition: flex-grow 0.5s ease;

    .main-container {
        flex: 1;
        border-radius: 0 12px 12px 0;
        display:flex;
        flex-direction: column;
        background: url(../images/bg_page.png) no-repeat bottom right;
        background-size: contain;

        main {
            flex:1;
            // background:#F5F5F5;
            background:#FFFFFF;

            &.overview-main {
                background: transparent;
            }
        }
    }
}

.player-header {
    font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
        align-items: center;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: #ffffffb3;
    background: var(--neutral-white-white-70, #ffffffb3);
    box-shadow: -15px 2px 10px 0 rgba(0, 0, 0, .102);
    color: #000;
    display: flex;
    font-family: TT Commons W05, Arial, Helvetica, sans-serif;
    height: 5rem;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 2rem;
    position: relative;
    top: 0;
    width: 100%;
    // z-index: 30;
    z-index: 112;

    .progress-indicator {
        flex-direction: row;
        align-items: center;
        gap:8px;
        .progress-info {
         white-space: nowrap;
        }
         .progress {
             height:9px;
             background:rgba(231, 231, 231, 1);
             // background: #010D2D40;
             // background:white;
             padding:0;
             min-width:80%;
             span {
                 left:0;
                 top:0;
                 height:100%;
                 transition:width 0.3s ease-in;
                 background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #7075F7 100%);
                 // background:#02835C;
             }
 
         }
     }
    .assignment-type {
        text-transform: capitalize;

        .assignment-type__wide {
            @include devices(small) {
                display: none;
            }
        }

        .assignment-type__mobile {
            display: none;

            @include devices(small) {
                display: inline-block;
            }
        }
    }

    .gr-btn {
        // margin-left: 3.5rem;
        color: #585858;
        &.icon-btn-18, &.icon-btn-24 {
            svg {
                fill: #585858;
            }
        }      

        &.btn-close {
            margin-left: 1rem;
            padding: 8px 20px;
            background-color: #03313E;
            border: 0;
            color: #fff;
            font-size: 1rem;

            @include devices(small) {
                margin: 0;
            }

            .mobile-view {
                display: none;

                @include devices(small) {
                    display: inline;
                }                
            }
            .desktop-view {
                display: inline;

                @include devices(small) {
                    display: none;
                }                
            }

            @include devices(small) {
                padding: 0.5rem;
                background: transparent;
            }

        }
    }

    .player-header__left {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        #info-icon {
            @include devices(medium) {
                display: none;
            }
        }

        .hamburger {
            display: none;
            position: relative;
            z-index: 1;
            user-select: none;
            background: transparent;
            border: none;

            @include devices(medium) {
                display: block;
            }

           > span {
               display: block;
                width: 1.5rem;
                height: 3px;
                margin-bottom: 5px;
                position: relative;
                background: #f5f5f5;
                border-radius: 3px;
                z-index: 1;
                transform-origin: 4px 0px;
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
           
                &:first-child {
                    transform-origin: 0% 0%;
                }
                
                &:nth-last-child(2) {
                    transform-origin: 0% 100%;
                }
            }

            &.open {
                > span {
                    opacity: 1;
                    transform: rotate(45deg) translate(-2px, -1px);
                    transform-origin: 50% 50%;
                    margin: 0;

                    &:nth-last-child(3) {
                        opacity: 0;
                        transform: rotate(0deg) scale(0.2, 0.2);
                    }

                    &:nth-last-child(2) {
                      transform: rotate(-45deg) translate(0, -1px);
                      transform-origin: 50% 50%;
                    }
                }
            }
        }
    }

    .player-header__center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        transition: left linear 0.3s;
        min-width:30%;

        @include devices(large) {
        //    left: 42%;
        // max-width:25%;
        min-width:15%;
        }

        @include devices(medium) {
            display: none;
        }

        .item-type {
            text-transform: capitalize;
            font-size: 1.25rem;
            font-weight: 600;
        }

        .step-info {
            font-weight: 400;
            font-size: 0.875rem;
        }
    }

    .player-header__right {
        display: flex;
        align-items: center;

        .mobile-score-btn {
            display: none;
            margin: 0;

            svg {
                fill: none !important;
                stroke: white;
            }

            @include devices(medium) {
                display: block;

                &:hover {
                    background-color: transparent;
                }

                &:active, &:focus {
                    outline: 0;
                    border: 0
                }
            }
        }

        .score-info {
            > span {
                display: inline-block;
                margin: 0 0.25rem;

                @include devices(large) {
                    margin: 0 0.125rem;
                }

                &.label {
                    font-weight: 600;
                }

                &.inline-val {
                    font-weight: 300;
                    white-space: nowrap;
                }

                &.divider {
                    @include devices(medium) {
                        display: none;
                    }
                }

                @include devices(large) {
                    font-size: 0.875rem;
                    
                }
            }

            @include devices(medium) {
                display: none;
                min-width: 225px;
                position: absolute;
                top: calc(100% + 0.125rem);
                right: 7.25rem;
                color: #333;
                background: #fff;
                border: 1px solid #ddd;
                padding: 0.5rem 0.75rem;
                gap: 0.5rem;
                border-radius: 0 0 0.25rem 0.25rem;
                box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);     

                &:before, &:after {
                    bottom: 99%;
                    right: 12px;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-bottom-color: #ffffff;
                    border-width: 10px;
                    margin-right: 0px;
                }

                &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-bottom-color: #c7c7c7;
                    border-width: 12px;
                    margin-right: -2px;
                }

                &.open {
                    display: grid;
                    grid-template-columns: 50% auto;
                }
            }

            @include devices(small) {
                right: 3.25rem;
            }
        }
    }
}

.player-main {
    display: flex;
    // height: calc(100vh - 9rem);
    max-height:calc(100dvh - 80px);
    position: relative;


    aside {
        max-width: 375px;
        position:fixed;
        z-index: 115;
        // box-shadow: 0px 1px 2px rgba(84, 110, 122, 0.24), 0px 2px 4px rgba(120, 144, 156, 0.24);
        transition: all ease-in-out 0.5s;
        border-right:1px solid rgba(51, 51, 51, 0.125);
        overflow-x:hidden;

        &:not(.collapsed) {
            height: 100%;
            backdrop-filter: blur(2px);
            background: #cfcfcf4d;
        }
        .aside-inner {
            display: flex;
            flex-direction: column;
            // height: 100%;
            max-height: calc(100vh - 5.5rem);
        }

        .scroll-btn {
            display: none;
        }

        nav {
            overflow: auto;
            flex: 1;
            padding: 0 1rem;
            scroll-behavior: smooth;

            @include devices(medium) {
                padding-top: 1.25rem;
            }

            @include devices(small) {
                padding-top: 0;
            }

            /* width */
            &::-webkit-scrollbar {
                width: 0.25rem;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f8f8f8;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #c7c7c7;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #999;
            }
        }

        .nav-top {
            flex: 0 0 2.5rem;
            width: 100%;
            padding: 0 0.5rem;
            background: #FAFAFA;
            border-bottom: 1px solid rgba(51, 51, 51, 0.125);
            margin-bottom: 0.25rem;
            box-shadow: 0px 3px 19px 0px rgba(0, 0, 0, 0.10);
            position:sticky;
            top:0;
            z-index: 11;
            #closeBtn {
                display: none;
            }

            @include devices(medium) {
                display: none;
            }

            @include devices(small) {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 0;
                background: transparent;
                border: 0;

                #minimizeBtn {
                    display: none;
                }

                #closeBtn {
                    display: block;
                    background: #f5f5f5;
                    border: 0;
                    width: 2.5rem;
                    height: 2.5rem;
                    padding: 0.5rem;
                    
                    svg {
                        margin: 0;
                    }
                }
            }
            
            .gr-btn {
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.0005em;
                color: rgba(51, 51, 51, 0.6);
                svg {
                    margin-right: 0.5rem;
                }
            }
        }

        &.collapsed {
            background: transparent;
            max-width: 7.125rem;
            border: 0;
            padding: 2rem;
            box-shadow: none;
            max-height: calc(100dvh - 140px);
            overflow:hidden;
            .aside-inner {background-color: #fff;}

            .progress-indicator {
                width: 0;
                height: 0;
                padding: 0;
                overflow: hidden;
            }

            .scroll-btn {
                display: none; /* change to 'block' when prototype has lots of assignment items */
                border-bottom: 1px solid #eee; 
                border-radius: 0;

                &:last-child {
                    border: 0;
                    border-top: 1px solid #eee;
                    border-radius: 0 0 0.5rem 0.5rem;
                }
            }

            .aside-inner {
                border: 0.5px solid #B6B6B6;
                box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                max-height: 100%;
            }

            nav {
                overflow: hidden;
                padding: 0;
                overflow-y: auto;
                
                /* width */
                &::-webkit-scrollbar {
                    width: 0.0625rem;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #fff;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #fff;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #fff;
                }
            }
            .nav-top {
                padding: 0 0.25rem;
                gap: 0;
                background-color: transparent;
                margin: 0;
                min-height:48px;
                .gr-btn {
                    span {
                        display: none;
                    }

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            /* Styles for assignment card are in _assignmentcard.scss */
        }

        &.closed {
            @include devices(medium) {
                height: 0;
                overflow: hidden;
                top: -10px;
            }
        }

        @include devices(medium) {
            position: absolute;
            left: 0;
            z-index: 20;
        }

        @include devices(small) {
            position: absolute;
            left: 0;
            top: -3rem;
            z-index: 50;
            width: 100%;
            max-width: none;
            background: rgba(0,0,0,0.7);
            height: calc(100vh - 4rem);
        }
    }
    article {
        flex: 1;
        height: 100%;
        overflow: auto;

        .item-content {
            max-height: calc(100% - 0.5rem); //footer
            height:100%;
            overflow: auto;
            position: relative;
            z-index: 5;
            padding-bottom: 0.5rem;
            margin-bottom:0.5rem;

            > h3 {
                font-weight: 600;
                max-width: 46.25rem;
                margin: 2rem auto 0;
            }
        }
        
        .player-footer {
            // height: 4.5rem;
            background: #FFF;
            // border-top: 1px solid rgba(225, 228, 241, 0.5);
            backdrop-filter: blur(7px);
            border-radius:100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap:36px;
            width:fit-content;
            min-width:122px;
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            // transform:translateX(-50%);
            box-shadow: 0 4px 15px 0 #0000001a;
            z-index: 10;
            padding:0.5rem;
            transition: left ease-in-out 0.5s;

            button {
                margin:0;
            }
            &__left {
                display: flex;
                align-items: center;
            }

            &__right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}

.content-part {
    max-width: 49.25rem;
    margin: 2rem auto;
    padding: 0 6rem;

    &.full-width, &.bronte-styles {
        max-width: none;
        margin: 2rem 4rem;
        position: relative;
        min-height: calc(100vh - 18rem);
    }

    h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 1rem;
    }
}
.video-player {
    width: 100%;
    height: auto;
    border: 1px solid #c7c7c7;
}
iframe {
    width: 100%;
    height: calc(100vh - 18rem);
    border: 2px solid #c7c7c7;
}

.question-stem {
    margin-bottom: 1rem;
}

.math-large {
    font-size: 2.25rem;
    line-height: 1rem;
}

.answer-options {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;

    li {
        margin: 0 !important;
        padding: 0 !important;
    }

    li:marker {
         display: none;
    }
}

.answer {
    margin-bottom: 1.5rem;

    .small-input {
        width: 3rem;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        font-size: 0.875rem;
        padding: 0.25rem;
    }
    .inline-select {
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        display: inline-block;
        font-size: 0.875rem;
        padding: 0.25rem;
    }

    .small {
        font-size: 0.875rem;
    }
}

.answer-status {
    background-color: #f5faf5;
    border-radius: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);

    .title {
        color: #038238;
        align-items: center;
        display: flex;
        font-size: 1rem;
        font-weight: 600;
        justify-content: space-between;
        padding: 0.75rem 1rem;
    }
}

/* Player footer actions that switch to dropdown in mobile view */
.mobile-dd-trigger {
    display:  none;

    @include devices(small) {
        display: inline-flex;
        gap: 0.5rem;
    }
}

.item-actions {
    display: flex;
    align-items: center; 

    @include devices(small) {
        display: none;
        flex-direction: column;
        background: #fff;
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
        padding: 0.25rem 1rem;

        &.open {
            display: flex;
            position: absolute;
            bottom: 3.25rem;
            left: 1rem;

        }
    }

    .gr-btn {
        @include devices(small) {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}