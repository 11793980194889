.overview-hero {    
    position: sticky;
    top: 0;
    padding: 1.5rem 0;
    min-height: 16rem;
    z-index: 2;
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(163, 200, 211, 0.5);

    @include devices(small) {
        padding: 1rem 0;
    }

    .overview-hero__right {
        container-type: inline-size;
    }

    &.pinned {
        @include devices(small) {
            min-height: 0;
        }
       .meta-data, .overview-hero__right, .progress-indicator {
            @include devices(small) {
                display: none;
            }
        }

        .action-bar {
            @include devices(small) {
                grid-row: 2;
            }
        }
    }

    .gradient {
        background: linear-gradient(80.83deg, rgba(232, 203, 192, 0.1) 33.76%, rgba(99, 111, 164, 0) 93.73%),linear-gradient(87.2deg, rgba(255, 255, 255, 0.9) 19.77%, rgba(255, 255, 255, 0) 99.48%), rgba(4, 122, 156, 0.4);   
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        z-index: 6;
    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            height: 100%;
            opacity: 0.5;
            background: linear-gradient(181.53deg, rgba(232, 203, 192, 0.8) -1.76%, rgba(99, 111, 164, 0.096) 98.7%);
            z-index: 5;
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.95) 85%, rgba(255,255,255,0.3) 100%);
        z-index: 2;
    }

    .wrapper {
        display: grid;
        grid-template-columns: 52% 40%;
        justify-content: space-between;
        opacity: 1;
        position: relative; 
        z-index: 10;

        @include devices(medium) {
            grid-template-columns: 100%;
            align-items: flex-start;
            gap: 1.25rem;

        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        margin-bottom: 0.75rem;

        @include devices(medium) {
            margin-bottom: 0;
        }
        
        .gr-btn {
            max-width: 8.25rem;
        }
    }

    &__right {
        grid-column: 2;
        grid-row: 1 / span 2;
            
        @include devices(medium) {
            grid-row: 2;
            grid-column: 1;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &.meta-data {
            display: flex;
            gap: 1rem;
            color: #333333;
            flex-wrap: wrap;
            margin: 0 !important;
            
            @include devices(small) {
                gap: 0.75rem;
            }

            a {
                font-weight: 600;
            }

            .label {
                font-weight: 600;
                margin-right: 0.25rem;
            }

            &.points {
                gap: .25rem;
                li {
                    padding:  0 0.5rem;
                    border-right: 1px solid rgba(0, 0, 0, 0.54);

                    &:last-child{
                        border: 0;
                        padding-right: 0;
                    }

                    &:first-child {
                        padding-left: 0;
                        padding-right: 0.75rem;
                    }
                }
            }
        }        
    }

    .assignment-type {
        font-size: 0.875rem;
        font-weight: 300;
        color: black;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        margin: 0;

        &:before {
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #017899;
            margin-right: 0.5rem;
        }
    }

    h2 {
        font-weight: 300;
        font-size: 2.125rem;
        line-height: 1;

        @include devices(small) {
            font-size: 1.5rem;
            font-weight: 400;
        }
    }

    .action-bar {
        display: flex;
        align-items: flex-end;
        gap: 1.5rem;
        margin-top: 1rem;

        @include devices(medium) {
            margin-top: 0;
        }
            
        @include devices(small) {
            grid-row: 3;
            gap: 1rem;
        }

        .gr-btn {
            margin: 0;

            @include devices(small) {
                display: block;    
                width: 100%;    
            }
        }
        
        @include devices(small) {
            flex-direction: column;
            align-items: flex-start;    
            width: 100%;        
        }
    }
}

.progress-indicator {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    transition: all 0.5s ease-in-out;

    .progress-info {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.4;
    }

    .progress {
        width: 100%;
        min-width: 18rem;
        height: 0.5rem;
        border-radius: 99px;
        background-color: #fff;
        position: relative;

        @include devices(large) {
            min-width: 12rem;
        }

        span {
            position: absolute;
            left: 0;
            top: 0;
            height: 0.5rem;
            border-radius: 9px;
            background: linear-gradient(270deg, #06A5C8 0%, #007899 100%);
        }
    }
}

.overall-score {
    background: url(../images/bg_overall_score.png) no-repeat calc(100% + 15px) calc(100% + 10px), linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
    border-radius: 0.5rem;
    width: 100%;
    padding: 1rem 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 11.25rem;

    h3 {
        font-size: 1.375rem;
        font-weight: 600;
        margin-bottom: 0.5rem;

        @include devices(small) {
            font-size: 1.125rem;
        }

        + span {
            @include devices(small) {
                font-size: 1rem;
            }
        }
    }

    &.in-progress {
        background-image: url(../images/bg_overall_score_inp.png), linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
    }

    &.complete {
        background-image: url(../images/bg_overall_score_done.png), linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
        background-position: right bottom;
    }

    /*@include devices(large) {
        padding: 1rem;
        background: url(../images/bg_overall_score.png) no-repeat calc(100% + 2rem) calc(100% + 10px), linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%); 
        background-size: 50%, auto;
    }

    @include devices(medium) {        
        background-size: auto, auto;
    }

    @include devices(small) {
        background: url(../images/bg_overall_score.png) no-repeat calc(100% + 1rem) calc(100% + 1rem), linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
        background-size: contain;
        height: 8.25rem;
    } */

    @container (width < 420px) {
        padding: 1rem;
        background: url(../images/bg_overall_score.png) no-repeat calc(100% + 2rem) calc(100% + 2rem), linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
        background-size: contain;
    }

    @container (width < 380px) {
        padding: 1rem;
        background: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
    }
}