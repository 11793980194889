@charset "UTF-8";
@import url(https://fast.fonts.net/cssapi/8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf.css);
@font-face {
  font-family: "Sabon LT W05 Regular";
  font-weight: normal;
  font-style: normal;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=48a81c65-a8bb-4d31-a9c1-dba0620b2c50&languages=de,en,es,fi,fr,pt&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=48a81c65-a8bb-4d31-a9c1-dba0620b2c50&languages=de,en,es,fi,fr,pt&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Sabon LT W05 Regular";
  font-weight: bold;
  font-style: italic;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=9c9b85e9-1095-4ba0-a99c-350f78aaf268&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=9c9b85e9-1095-4ba0-a99c-350f78aaf268&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Sabon LT W05 Regular";
  font-style: italic;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=4318c8c4-489b-40b6-a984-45a862c647f7&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=4318c8c4-489b-40b6-a984-45a862c647f7&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Sabon LT W05 Regular";
  font-weight: bold;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=4a892475-9142-44aa-8845-6b05a8ac0659&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=4a892475-9142-44aa-8845-6b05a8ac0659&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Slate W05 Regular";
  font-weight: normal;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=a9041bce-2585-4b11-8e23-bed48f93672e&languages=de,en,es,fi,fr,pt&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=a9041bce-2585-4b11-8e23-bed48f93672e&languages=de,en,es,fi,fr,pt&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Slate W05 Regular";
  font-weight: bold;
  font-style: normal;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=f27f497a-b596-45c3-a60c-0819106f626e&languages=de,en,es,fi,fr,pt&ot=true&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=f27f497a-b596-45c3-a60c-0819106f626e&languages=de,en,es,fi,fr,pt&ot=true&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Slate W05 Regular";
  font-style: italic;
  font-weight: normal;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=172dbfc8-2d43-4fbc-85a6-3cfb7375dc4c&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=172dbfc8-2d43-4fbc-85a6-3cfb7375dc4c&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }

@font-face {
  font-family: "Slate W05 Regular";
  font-weight: bold;
  font-style: italic;
  src: url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=14&fcId=c7cd4e86-5f2c-4ca2-be3c-d221c1009c14&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff2"), url("https://api2.fonts.com/FontSubsetter.ashx?d44f19a684109620e484147fa790e81859e92aaaea3d337f84586d5df8888fe5455f55e0f83ed0be044ddfaa95e824a4b1318d5b552aaa24a44025e9&fctypeId=3&fcId=c7cd4e86-5f2c-4ca2-be3c-d221c1009c14&languages=en,fi,fr,de,pt,es&ot=false&projectId=8b3bf2d0-cef1-445b-8483-7cb8c7ee19bf") format("woff"); }


  .bronte-styles {
    font-family: "Sabon LT W05 Regular", serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  background: unset !important;
  }
  .bronte-styles * {
    font-family: "Sabon LT W05 Regular", serif;
  }
  .bronte-styles p {
    font-family: "Sabon LT W05 Regular", serif;
  font-size: 1.1875em;
  line-height: 1.875em;
  color: #000;
  margin-top: 8px;
  margin-bottom: 8px;
  }
  .stage-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    inset: 0px;
    overflow: hidden auto;
    margin-bottom: 0px;
    backface-visibility: hidden;
    
  }

  .stage-box {
    width: 100%;
    position: relative;
    padding-bottom: 70px;
  }

  .spf-rce-stage {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }

  .spf-rce-section {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
  }

  .spf-rce-section-content {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px auto;
    background-color: rgb(255, 255, 255);
    width: 100%;
    /* min-width: 940px; */
    max-width: 940px;
    padding: 0px 60px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
  }

  .spf-rce-row {
    position: relative;
    display: flex;
    width: 100%;
  }

  .spf-rce-column {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    min-width: 15px;
  }

  .spf-rce-section-content .spf-rce-element {
    margin: 0px 0px 0px 5px;
  }

  .chapterIntro figure{
    place-items: center;
  }

  figure {
    outline: none;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }

  .spf-element-image {
    margin-left: 0px;
  }

  .spf-inline-reference-link {
    font-size: inherit;
    text-decoration: none;
    color: rgb(0, 115, 202);
  }

  .longdesccontent{
    visibility: hidden;
    height:0;
    position: absolute;
    left: -10000px;
    top: -10000px;
  }

  .codeBlockWrapper > pre {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 0.875rem;
  }
  .viewer-container .stage-container .pearson-component.audio {
    margin: 0;
  }

  .listClass { 
    list-style-type: none;
  }
  
  .spf-rce-column .stage-element.spf-rce-element:last-child:has(.divVideo) {
    height: max-content;
  }
  
    .table-align-middle {
        vertical-align: middle;
    }

    .table-align-top {
        vertical-align: top;
    }

    .table-align-bottom {
        vertical-align: bottom;
    }

    .table-align-left {
        text-align: left;
    }

    .table-align-center {
        text-align: center;
    }

    .table-align-right {
        text-align: right;
    }

    @media (max-width: 480px) {
        .table-overflow-scroll {
            overflow-x: auto;
        }
    }

article.assignment-content .bronte-styles {
  font-family: "Sabon LT W05 Regular", serif;
  line-height: 2.1875em;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  background: unset; }

article.assignment-content  .bronte-styles p {
  font-family: "Sabon LT W05 Regular", serif;
  font-size: 1.1875em;
  line-height: 1.75em;
  color: #000;
  margin-top: 2px;
  margin-bottom: 2px; }

article.assignment-content  .bronte-styles .spf-rce-section-content {
  background-color: unset; }

p > p {
  font-size: inherit; }

.spf-rce-element.spf-element-text p {
  padding-bottom: 0px; }
  
  /* set up container */
  .bronte-styles {
    container-type: inline-size;
  }
/* Head Levels */
article.assignment-content  .bronte-styles h1 {
  font-family: "Sabon LT W05 Regular", sans-serif;
  font-weight: normal;
  font-size: 3.375em;
  color: #000;
  line-height: normal;
  letter-spacing: 0.02em;
  word-break: normal;
  margin: 1em 0 0 0;
  position: relative;
  left: -7.5vw;
 }
 .bronte-styles h1::before {
  content: "";
  position: absolute;
  background-color: #CC0300;
  width: calc(200% - 4px);
  height: 2px;
  margin: 0.7em 0 0 calc(-200% - 20px); }
.bronte-styles h1 .number {
  font-family: "Slate W05 Regular", sans-serif;
  font-weight: normal;
  font-size: inherit;
  line-height: 3em;
  letter-spacing: 1px; }

@container (width < 1080px) {
  article.assignment-content .bronte-styles h1 {
     left: 0vw; } }


@container (width < 640px) {
  article.assignment-content .bronte-styles h1 {
    font-size: 2em;
    letter-spacing: unset; } }
    @container (width < 640px) {
      .bronte-styles h1::before {
        content: none; } }


/* article.assignment-content .spf-rce-stage .spf-rce-section.chapterIntro:first-child .spf-rce-row:first-child .spf-element-text h1 .number::after {
  content: none;
} */
article.assignment-content .bronte-styles h2 {
  font-family: "Sabon LT W05 Regular", sans-serif;
  font-weight: 400;
  font-size: 2.85em;
  color: #000;
  line-height: normal;
  letter-spacing: 0.01em;
  word-break: normal;
  margin: 1em 0 0.25em 0;
  position: relative;
  left: -7.5vw;
}
.spf-rce-stage h2::before {
  content: "";
  position: absolute;
  background-color: #CC0300;
  width: 1.5em;
  height: 2px;
  top: -0.4em; }

@container (width < 1080px) {
  article.assignment-content .bronte-styles h2 {
    left: 0vw; } }

@container (width < 640px) {
  article.assignment-content .bronte-styles h2 {
    font-size: 1.7em;
    letter-spacing: unset; }
  .bronte-styles h2::before {
    display: none; }
  }

article.assignment-content .bronte-styles h3 {
  font-family: "Sabon LT W05 Regular", sans-serif;
  font-weight: 400;
  font-size: 1.7em;
  color: #000;
  letter-spacing: 0.05em;
  word-break: normal;
  margin: 1em 0 0 0;
  position: relative; }

@container (width < 640px) {
  article.assignment-content .bronte-styles h3 {
    font-size: 1.4em;
    letter-spacing: unset; } }

article.assignment-content .bronte-styles h4 {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 1.23em;
  font-weight: bold;
  line-height: 1.5em;
  word-break: normal;
  color: #000;
  letter-spacing: 0.05em;
  margin: 1em 0 0 0;
  position: relative; }

@container (width < 640px) {
  article.assignment-content .bronte-styles h4 {
    font-size: 1.125rem;
    letter-spacing: unset; } }

article.assignment-content .bronte-styles h5 {
  font-family: "Slate W05 Regular", sans-serif;
  font-weight: 600;
  font-size: 1.135em;
  font-style: italic;
  color: #000;
  letter-spacing: 0.02em;
  word-break: normal;
  margin: 1em 0 0 0;
  position: relative; }
@container (width < 640px) {
    .bronte-styles h5 {
      font-size: 1.125em;
      letter-spacing: unset; } }

article.assignment-content .bronte-styles h6 {
  font-family: "Slate W05 Regular", sans-serif;
  font-weight: normal;
  font-size: 1.150em;
  color: #000;
  letter-spacing: 0.02em;
  word-break: normal;
  margin: 1em 0 0 0;
  position: relative; }
@container (width < 640px) {
    .bronte-styles h6 {
      font-size: 1.125em;
      letter-spacing: unset; } }
/* Math Styles */
.mathml-epub-block {
  color: #000; }

.math_ans {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  color: #1B7D60;
  font-weight: bold; }

.matheq_number {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  color: #555;
  font-weight: bold; }

.nonpop_up_footnote {
  font-size: 75%; }

@media screen and (max-width: 800px) {
  .mathml-epub-block {
    overflow-x: scroll; } }

@media screen and (max-width: 800px) {
  div.math-expression {
    overflow-x: scroll; } }

.math_scroll {
  overflow-x: scroll; }

.p_caption p {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-style: normal;
  text-align: left;
  width: 100%;
  line-height: 1.6em;
  font-size: 1em; }

.p_credit p {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-style: normal;
  text-align: left;
  width: 100%;
  line-height: 1.6em;
  font-size: .90em; }

/* Paragraph Indent Styles */
article.assignment-content .bronte-styles .paragraph-indent1 {
  margin-left: 70px !important; }

  @container (width < 425px) {
  article.assignment-content .bronte-styles .paragraph-indent1 {
    margin-left: 40px !important; } }

article.assignment-content .bronte-styles .list-indent1 {
  margin-left: 38px !important; }

article.assignment-content .bronte-styles .list-indent2 {
  margin-left: 77px !important; }

article.assignment-content .bronte-styles .list-indent3 {
  margin-left: 110px !important; }

/* Link */
article.assignment-content .bronte-styles a {
  color: #CC0300 !important; }

article.assignment-content .bronte-styles p a {
  color: #CC0300 !important;
  cursor: pointer;
  text-decoration: underline !important; }

article.assignment-content .bronte-styles a .label {
  color: #CC0300; }

article.assignment-content .bronte-styles .spf-rce-stage a span.number.spf-inline-span {
  color: #CC0300;
  word-break: break-word; }

article.assignment-content .bronte-styles a::after {
  display: none; }

  a.keyword {
    text-decoration: underline !important;
    color: #CC0300 !important; }

/* Lists */
article.assignment-content .bronte-styles ol li {
  font-family: "Sabon LT W05 Regular", serif;
  margin: 0 0 16px 0.75em;
  padding-left: 3px;
  color: #000; }

.bronte-styles ol li {
  display: list-item !important; }

article.assignment-content .bronte-styles ul {
  list-style-type: disc; }

article.assignment-content .bronte-styles ul li {
  font-family: "Sabon LT W05 Regular", serif;
  margin: 0 0 16px 0.75em;
  color: #000; }

article.assignment-content .bronte-styles ul li::marker {
  color: #CC0300; }

article.assignment-content .bronte-styles ol li::marker {
  font-weight: 800; }

article.assignment-content .bronte-styles .ul_none ul li::before {
  content: none; }

article.assignment-content .bronte-styles .ul_none ul li {
  content: none;
  list-style-type: none; }

.ul_none .number {
  font-family: "Sabon LT W05 Regular", serif;
  margin-right: 24px;
  position: absolute;
  color: #000;
  text-align: right;
  margin: 0 0 1em -5em;
  width: 4.5em;
  font-weight: bold;
  text-decoration: none !important; }
  
  @container (width < 425px) {
    .ul_none ul {
      margin-left: 0em !important; } }
  
      @container (width < 375px) {
    .ul_none ul {
      margin-left: -3em !important; } }
  
      @container (width < 425px) {
    .ul_none ul ul {
      margin-left: -3em !important; } }
  
      @container (width < 425px) {
    .ul_none .number {
      position: relative;
      padding-right: 0.25em;
      margin-left: 0 !important;
      left: unset !important; } }
  
      @container (width < 425px) {
    .ul_none ul ul ul ul li .number {
      margin: 0 !important; } }

article.assignment-content .bronte-styles ol p,
article.assignment-content .bronte-styles ul p {
  font-size: 1.2em; }

ul li p ol li p {
  font-size: 1em; }

ol p ol li p {
  font-size: 1em; }

/* Nested UL List Styles */
ul ul,
ul ul ul,
ul ul ul ul {
  margin-left: 1.5em; }

  @container (width < 640px) {
  ol {
    margin-left: -1.2em !important; } }

    @container (width < 640px) {
  ol ol {
    margin-left: -2em !important; } }

    @container (width < 640px) {
  ol ol ol {
    margin-left: -2em !important; } }

    @container (width < 640px) {
  ul {
    margin-left: -2.2em !important; } }

    @container (width < 640px) {
  ul ul {
    margin-left: -2em !important; } }

    @container (width < 640px) {
  ul ul ul {
    margin-left: -2em !important; } }

/* Outline */
.outline ol li {
  margin: 0em;
  line-height: 1.35em;
  padding-top: 0.5em; }

.outline ol ol li {
  margin-left: -0.85em;
  padding-top: 0em; }

.outline ol ol li:first-child {
  margin-top: -0.2em; }

.outline ol ol ol li {
  margin-left: -1em; }

.outline ol ol ol li:first-child {
  margin-top: 0em; }

.outline ol ol ol ol li {
  margin-left: -1em; }

.outline ol li p {
  line-height: 1.35em; }

/* Outline Authoring Code */
.spf-parallax-wrapper .outline ol ol ol li {
  margin-left: -1.9em; }

.spf-parallax-wrapper .outline ol ol ol ol li {
  margin-left: -2.8em; }

  @container (width < 425px) {
  .outline ol ol li {
    margin-left: 1em; } 
  .outline ol ol ol li {
    margin-left: 1em; }
  .outline ol ol ol ol li {
    margin-left: 1em; } }

/* Figure */
.spf-rce-element.spf-element-image figure img {
  margin-bottom: 0; }

/* .spf-rce-element.spf-element-image figure {
    margin-bottom: 1em;
}  */
.spf-rce-element.spf-element-text .figure-title p {
  color: #000;
  font-family: "Slate W05 Regular", sans-serif;
  width: 100%;
  font-size: 1.125em;
  line-height: 1.5em; }

.spf-rce-element.spf-element-text p .genericLabel {
  color: #CC0300 !important;
  font-size: 1.05em;
  font-weight: bold;
  display: inline-block;
  font-family: "Slate W05 Regular", sans-serif;
  margin-bottom: -.35em; }

.spf-rce-element.spf-element-text p .genericLabel:before {
  content: '';
  position: absolute;
  background-color: #CC0300;
  width: 2.80em;
  height: 2px;
  left: 4px;
  margin-top: -0.35em; }

div.figure-title p:nth-child(2) {
  font-family: "Slate W05 Regular", sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.120em;
  line-height: 1.35em; }

div.figure-title {
  margin-bottom: -.65em !important;
  margin-top: 1em !important; }

.spf-rce-element.spf-element-text p .genericLabel:hover {
  background-color: transparent; }

/* Figure Caption */
figcaption {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-style: normal;
  text-align: left;
  width: 100%;
  margin: 0.25em 0 0.5em 0; }

figcaption p {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1.0em;
  color: #000;
  width: 100%;
  line-height: 1.4;
  font-style: normal;
  text-align: left;
  margin-bottom: 0; }

figure figcaption p.caption-text {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1.0em;
  line-height: 1.4em;
  font-style: normal;
  text-align: left; }

figure figcaption p.caption-credit {
  font-size: 0.9375em;
  line-height: 1.45em;
  font-style: normal;
  text-align: left;
  color: #000;
  width: 100%; }

figure figcaption p:nth-child(2) {
  color: #000;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 0.9125em;
  line-height: 1.45em;
  margin: 0.35em 0 0 0; }

.hidecaption figcaption {
  visibility: hidden; }

.spf-rce-image-wrapper {
  height: max-content !important; }

/* Asides */
.aside-group {
  background: none;
  border-radius: 0;
  border: none; }

.aside-group {
  padding: 0; }

.aside-group.Sidebar_1Aside,
.aside-group.Sidebar_2Aside,
.aside-group.Sidebar_3Aside,
.aside-group.Sidebar_4Aside,
.aside-group.Sidebar_5Aside,
.aside-group.Sidebar_6Aside,
.aside-group.Sidebar_7aAside,
.aside-group.Sidebar_7bAside,
.aside-group.Sidebar_7cAside,
.aside-group.Sidebar_7dAside,
.aside-group.Sidebar_7eAside,
.aside-group.Sidebar_7fAside {
  /* background colors are controlled by pseudo class */
  border: none;
  border-radius: 0;
  padding: 1.8em 2.4em 2.4em 2.4em;
  margin-top: 2em;
  margin-bottom: 1em; }

.aside-group.Sidebar_1Aside .spf-rce-section-content,
.aside-group.Sidebar_2Aside .spf-rce-section-content,
.aside-group.Sidebar_3Aside .spf-rce-section-content,
.aside-group.Sidebar_4Aside .spf-rce-section-content,
.aside-group.Sidebar_5Aside .spf-rce-section-content,
.aside-group.Sidebar_6Aside .spf-rce-section-content,
.aside-group.Sidebar_7aAside .spf-rce-section-content,
.aside-group.Sidebar_7bAside .spf-rce-section-content,
.aside-group.Sidebar_7cAside .spf-rce-section-content,
.aside-group.Sidebar_7dAside .spf-rce-section-content,
.aside-group.Sidebar_7eAside .spf-rce-section-content,
.aside-group.Sidebar_7fAside .spf-rce-section-content,
.Sidebar_1Aside .spf-rce-section-content,
.Sidebar_2Aside .spf-rce-section-content,
.Sidebar_3Aside .spf-rce-section-content,
.Sidebar_4Aside .spf-rce-section-content,
.Sidebar_5Aside .spf-rce-section-content,
.Sidebar_6Aside .spf-rce-section-content,
.Sidebar_7aAside .spf-rce-section-content,
.Sidebar_7bAside .spf-rce-section-content,
.Sidebar_7cAside .spf-rce-section-content,
.Sidebar_7dAside .spf-rce-section-content,
.Sidebar_7eAside .spf-rce-section-content,
.Sidebar_7fAside .spf-rce-section-content {
  background-color: transparent;
  padding-top: 2em;
  padding-bottom: 2em; }

.aside-group.Sidebar_1Aside .spf-rce-section-content:after,
.aside-group.Sidebar_2Aside .spf-rce-section-content:after,
.aside-group.Sidebar_3Aside .spf-rce-section-content:after,
.aside-group.Sidebar_4Aside .spf-rce-section-content:after,
.aside-group.Sidebar_5Aside .spf-rce-section-content:after,
.aside-group.Sidebar_6Aside .spf-rce-section-content:after,
.aside-group.Sidebar_7aAside .spf-rce-section-content:after,
.aside-group.Sidebar_7bAside .spf-rce-section-content:after,
.aside-group.Sidebar_7cAside .spf-rce-section-content:after,
.aside-group.Sidebar_7dAside .spf-rce-section-content:after,
.aside-group.Sidebar_7eAside .spf-rce-section-content:after,
.aside-group.Sidebar_7fAside .spf-rce-section-content:after,
.Sidebar_1Aside .spf-rce-section-content:after,
.Sidebar_2Aside .spf-rce-section-content:after,
.Sidebar_3Aside .spf-rce-section-content:after,
.Sidebar_4Aside .spf-rce-section-content:after,
.Sidebar_5Aside .spf-rce-section-content:after,
.Sidebar_6Aside .spf-rce-section-content:after,
.Sidebar_7aAside .spf-rce-section-content:after,
.Sidebar_7bAside .spf-rce-section-content:after,
.Sidebar_7cAside .spf-rce-section-content:after,
.Sidebar_7dAside .spf-rce-section-content:after,
.Sidebar_7eAside .spf-rce-section-content:after,
.Sidebar_7fAside .spf-rce-section-content:after {
  position: absolute;
  content: "";
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0em;
  background-color: #f7f7f7;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  z-index: -10; }

.aside-group.Sidebar_1Aside .spf-rce-section-content:after,
.Sidebar_1Aside .spf-rce-section-content:after {
  background-color: #f7f7f7; }

@container (width < 960px) {
  .aside-group.Sidebar_1Aside .spf-rce-section-content:after,
  .Sidebar_1Aside .spf-rce-section-content:after {
    left: -1.5em; } }

@container (width < 640px) {
  .aside-group.Sidebar_1Aside .spf-rce-section-content:after,
  .Sidebar_1Aside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_2Aside .spf-rce-section-content:after,
.Sidebar_2Aside .spf-rce-section-content:after {
  background-color: #EBF6FF; }

@container (width < 960px) {
  .aside-group.Sidebar_2Aside .spf-rce-section-content:after,
  .Sidebar_2Aside .spf-rce-section-content:after {
    left: -1.5em; } }

  @container (width < 640px) {
  .aside-group.Sidebar_2Aside .spf-rce-section-content:after,
  .Sidebar_2Aside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_3Aside .spf-rce-section-content:after,
.Sidebar_3Aside .spf-rce-section-content:after {
  background-color: #EDFDF2; }

  @container (width < 960px) {
  .aside-group.Sidebar_3Aside .spf-rce-section-content:after,
  .Sidebar_3Aside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_3Aside .spf-rce-section-content:after,
  .Sidebar_3Aside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_4Aside .spf-rce-section-content:after,
.Sidebar_4Aside .spf-rce-section-content:after {
  background-color: #FFFFC7; }

  @container (width < 960px) {
  .aside-group.Sidebar_4Aside .spf-rce-section-content:after,
  .Sidebar_4Aside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_4Aside .spf-rce-section-content:after,
  .Sidebar_4Aside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_5Aside .spf-rce-section-content:after,
.Sidebar_5Aside .spf-rce-section-content:after {
  background-color: #F7F3F9; }

  @container (width < 960px) {
  .aside-group.Sidebar_5Aside .spf-rce-section-content:after,
  .Sidebar_5Aside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_5Aside .spf-rce-section-content:after,
  .Sidebar_5Aside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_6Aside .spf-rce-section-content:after,
.Sidebar_6Aside .spf-rce-section-content:after {
  background-color: #fff;
  border: 1px solid #999; }

  @container (width < 960px) {
  .aside-group.Sidebar_6Aside .spf-rce-section-content:after,
  .Sidebar_6Aside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7aAside .spf-rce-section-content:after,
  .Sidebar_7aAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_7aAside .spf-rce-section-content:after,
.Sidebar_7aAside .spf-rce-section-content:after {
  background-color: #fff;
  border: 2px solid #d43f11; }

  @container (width < 960px) {
  .aside-group.Sidebar_7aAside .spf-rce-section-content:after,
  .Sidebar_7aAside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7aAside .spf-rce-section-content:after,
  .Sidebar_7aAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_7bAside .spf-rce-section-content:after,
.Sidebar_7bAside .spf-rce-section-content:after {
  background-color: #fff;
  border: 2px solid #486399; }

  @container (width < 960px) {
  .aside-group.Sidebar_7bAside .spf-rce-section-content:after,
  .Sidebar_7bAside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7bAside .spf-rce-section-content:after,
  .Sidebar_7bAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_7cAside .spf-rce-section-content:after,
.Sidebar_7cAside .spf-rce-section-content:after {
  background-color: #fff;
  border: 2px solid #307F05; }

  @container (width < 960px) {
  .aside-group.Sidebar_7cAside .spf-rce-section-content:after,
  .Sidebar_7cAside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7cAside .spf-rce-section-content:after,
  .Sidebar_7cAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_7dAside .spf-rce-section-content:after,
.Sidebar_7dAside .spf-rce-section-content:after {
  background-color: #fff;
  border: 2px solid #8f5f00; }

  @container (width < 960px) {
  .aside-group.Sidebar_7dAside .spf-rce-section-content:after,
  .Sidebar_7dAside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7dAside .spf-rce-section-content:after,
  .Sidebar_7dAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_7eAside .spf-rce-section-content:after,
.Sidebar_7eAside .spf-rce-section-content:after {
  background-color: #fff;
  border: 2px solid #714983; }

  @container (width < 960px) {
  .aside-group.Sidebar_7eAside .spf-rce-section-content:after,
  .Sidebar_7eAside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7eAside .spf-rce-section-content:after,
  .Sidebar_7eAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.aside-group.Sidebar_7fAside .spf-rce-section-content:after,
.Sidebar_7fAside .spf-rce-section-content:after {
  background-color: #fff;
  border: 2px solid #327f8b; }

  @container (width < 960px) {
  .aside-group.Sidebar_7fAside .spf-rce-section-content:after,
  .Sidebar_7fAside .spf-rce-section-content:after {
    left: -1.5em; } }

    @container (width < 640px) {
  .aside-group.Sidebar_7fAside .spf-rce-section-content:after,
  .Sidebar_7fAside .spf-rce-section-content:after {
    padding-bottom: 1em;
    border-radius: 0;
    left: unset; } }

.spf-rce-section.aside-group.Sidebar_1Aside h3,
.spf-rce-section.aside-group.Sidebar_2Aside h3,
.spf-rce-section.aside-group.Sidebar_3Aside h3,
.spf-rce-section.aside-group.Sidebar_4Aside h3,
.spf-rce-section.aside-group.Sidebar_5Aside h3,
.spf-rce-section.aside-group.Sidebar_6Aside h3,
.spf-rce-section.aside-group.Sidebar_7aAside h3,
.spf-rce-section.aside-group.Sidebar_7bAside h3,
.spf-rce-section.aside-group.Sidebar_7cAside h3,
.spf-rce-section.aside-group.Sidebar_7dAside h3,
.spf-rce-section.aside-group.Sidebar_7eAside h3,
.spf-rce-section.aside-group.Sidebar_7fAside h3 {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  color: #000;
  margin-bottom: -0.25em;
  font-size: 1.30em;
  font-weight: 100;
  letter-spacing: 0;
  left: 0; }

.aside-group.Sidebar_1Aside h2,
.aside-group.Sidebar_2Aside h2,
.bronte-styles .aside-group.Sidebar_3Aside h2,
.aside-group.Sidebar_4Aside h2,
.aside-group.Sidebar_5Aside h2,
.aside-group.Sidebar_6Aside h2,
.aside-group.Sidebar_7aAside h2,
.aside-group.Sidebar_7bAside h2,
.aside-group.Sidebar_7cAside h2,
.aside-group.Sidebar_7dAside h2,
.aside-group.Sidebar_7eAside h2,
.aside-group.Sidebar_7fAside h2 {
  font-family: "Sabon LT W05 Regular", sans-serif;
    font-size: 1.55em;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.02em;
    margin-top: 0.25em;
    margin-bottom: 0;
    left: 0;
  }

  @container (width < 640px) {
  .aside-group.Sidebar_1Aside h2,
  .aside-group.Sidebar_2Aside h2,
  .aside-group.Sidebar_3Aside h2,
  .aside-group.Sidebar_4Aside h2,
  .aside-group.Sidebar_5Aside h2,
  .aside-group.Sidebar_6Aside h2,
  .aside-group.Sidebar_7aAside h2,
  .aside-group.Sidebar_7bAside h2,
  .aside-group.Sidebar_7cAside h2,
  .aside-group.Sidebar_7dAside h2,
  .aside-group.Sidebar_7eAside h2,
  .aside-group.Sidebar_7fAside h2 {
    padding-top: 1em; } }

.stage-container .aside-group.Sidebar_1Aside .spf-rce-row p,
.stage-container .aside-group.Sidebar_1Aside .spf-rce-row em,
.stage-container .aside-group.Sidebar_1Aside .spf-rce-row strong {
  padding: 0; }

.aside-group.Sidebar_1Aside h2,
.aside-group.Sidebar_2Aside h2,
.aside-group.Sidebar_3Aside h2,
.aside-group.Sidebar_4Aside h2,
.aside-group.Sidebar_5Aside h2,
.aside-group.Sidebar_6Aside h2,
.aside-group.Sidebar_7aAside h2,
.aside-group.Sidebar_7bAside h2,
.aside-group.Sidebar_7cAside h2,
.aside-group.Sidebar_7dAside h2,
.aside-group.Sidebar_7eAside h2,
.aside-group.Sidebar_7fAside h2 {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1.75em;
  font-weight: 800;
  text-transform: none;
  margin-bottom: 0;
  left: 0; }

/* Aside Label Colors */
.aside-group.Sidebar_1Aside h2 .label {
  color: #d43f11;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_2Aside h2 .label {
  color: #486399;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_3Aside h2 .label {
  color: #307F05;
  font-family: "Sabon LT W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: bold;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_4Aside h2 .label {
  color: #856F00;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_5Aside h2 .label {
  color: #714983;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_6Aside h2 .label {
  color: #327f8b;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_7aAside h2 .label {
  color: #d43f11;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_7bAside h2 .label {
  color: #486399;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_7cAside h2 .label {
  color: #307F05;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_7dAside h2 .label {
  color: #8f5f00;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_7eAside h2 .label {
  color: #714983;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.aside-group.Sidebar_7fAside h2 .label {
  color: #327f8b;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: 1em;
  font-weight: 800;
  text-transform: none;
  letter-spacing: 0.1rem;
  margin-top: 0.25em;
  margin-bottom: 0;
  left: 0; }

.Sidebar_6Aside h2 .react-mathjax-preview-result {
  color: #327f8b; }

.Sidebar_7aAside h2 .react-mathjax-preview-result {
  color: #d43f11; }

.Sidebar_7bAside h2 .react-mathjax-preview-result {
  color: #486399; }

.Sidebar_7cAside h2 .react-mathjax-preview-result {
  color: #307F05; }

.Sidebar_7dAside h2 .react-mathjax-preview-result {
  color: #8f5f00; }

.Sidebar_7eAside h2 .react-mathjax-preview-result {
  color: #714983; }

.Sidebar_7fAside h2 .react-mathjax-preview-result {
  color: #327f8b; }

.aside-group.Sidebar_1Aside p,
.aside-group.Sidebar_1Aside em,
.aside-group.Sidebar_1Aside strong {
  padding: 0; }

.aside-group.Sidebar_1Aside::before {
  display: none; }

  @container (width < 960px) {
  .aside-group.aside-group.Sidebar_1Aside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_2Aside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_3Aside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_4Aside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_5Aside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_6Aside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_7aAside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_7bAside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_7cAside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_7dAside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_7eAside .spf-rce-section-content,
  .aside-group.aside-group.Sidebar_7fAside .spf-rce-section-content,
  .Sidebar_1Aside .spf-rce-section-content,
  .Sidebar_2Aside .spf-rce-section-content,
  .Sidebar_3Aside .spf-rce-section-content,
  .Sidebar_4Aside .spf-rce-section-content,
  .Sidebar_5Aside .spf-rce-section-content,
  .Sidebar_6Aside .spf-rce-section-content,
  .Sidebar_7aAside .spf-rce-section-content,
  .Sidebar_7bAside .spf-rce-section-content,
  .Sidebar_7cAside .spf-rce-section-content,
  .Sidebar_7dAside .spf-rce-section-content,
  .Sidebar_7eAside .spf-rce-section-content,
  .Sidebar_7fAside .spf-rce-section-content {
    padding-left: unset;
    padding-right: unset; } }

    @container (width < 960px) {
  .aside-group.Sidebar_1Aside .spf-rce-section-content:after,
  .aside-group.Sidebar_2Aside .spf-rce-section-content:after,
  .aside-group.Sidebar_3Aside .spf-rce-section-content:after,
  .aside-group.Sidebar_4Aside .spf-rce-section-content:after,
  .aside-group.Sidebar_5Aside .spf-rce-section-content:after,
  .aside-group.Sidebar_6Aside .spf-rce-section-content:after,
  .aside-group.Sidebar_7aAside .spf-rce-section-content:after,
  .aside-group.Sidebar_7bAside .spf-rce-section-content:after,
  .aside-group.Sidebar_7cAside .spf-rce-section-content:after,
  .aside-group.Sidebar_7dAside .spf-rce-section-content:after,
  .aside-group.Sidebar_7eAside .spf-rce-section-content:after,
  .aside-group.Sidebar_7fAside .spf-rce-section-content:after,
  .Sidebar_1Aside .spf-rce-section-content:after,
  .Sidebar_2Aside .spf-rce-section-content:after,
  .Sidebar_3Aside .spf-rce-section-content:after,
  .Sidebar_4Aside .spf-rce-section-content:after,
  .Sidebar_5Aside .spf-rce-section-content:after,
  .Sidebar_6Aside .spf-rce-section-content:after,
  .Sidebar_7aAside .spf-rce-section-content:after,
  .Sidebar_7bAside .spf-rce-section-content:after,
  .Sidebar_7cAside .spf-rce-section-content:after,
  .Sidebar_7dAside .spf-rce-section-content:after,
  .Sidebar_7eAside .spf-rce-section-content:after,
  .Sidebar_7fAside .spf-rce-section-content:after {
    left: unset;
    width: 105vw;
    border-radius: unset; } }

    @container (width < 960px) {
  .aside-group.Sidebar_1Aside,
  .aside-group.Sidebar_2Aside,
  .aside-group.Sidebar_3Aside,
  .aside-group.Sidebar_4Aside,
  .aside-group.Sidebar_5Aside,
  .aside-group.Sidebar_6Aside,
  .aside-group.Sidebar_7aAside,
  .aside-group.Sidebar_7bAside,
  .aside-group.Sidebar_7cAside,
  .aside-group.Sidebar_7dAside,
  .aside-group.Sidebar_7eAside,
  .aside-group.Sidebar_7fAside {
    padding-left: 70px;
    padding-right: 50px; } }

@container (width < 640px) {
  .aside-group.Sidebar_1Aside,
  .aside-group.Sidebar_2Aside,
  .aside-group.Sidebar_3Aside,
  .aside-group.Sidebar_4Aside,
  .aside-group.Sidebar_5Aside,
  .aside-group.Sidebar_6Aside,
  .aside-group.Sidebar_7aAside,
  .aside-group.Sidebar_7bAside,
  .aside-group.Sidebar_7cAside,
  .aside-group.Sidebar_7dAside,
  .aside-group.Sidebar_7eAside,
  .aside-group.Sidebar_7fAside {
    padding-top: 1em;
    padding-left: 0px;
    padding-right: 0px; } }

/* Feature */
.aside-group.featureAside {
  background: #CC0300;
  border-left: none;
  border-top: none;
  border-bottom: none;
  padding: 1em 0 1em 0;
  margin: 3em 0 3em 0; }

.featureAside p {
  color: #fff; }

.SelectionMenuWrapper-sc-1ww5eng-0.styles__SelectionMenuWrapperExtended-sc-1tbwvuu-1 {
  background-color: #263142 !important; }

@container (width < 640px) {
  .aside-group.featureAside {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 2em; } }

.aside-group.featureAside h2,
.aside-group.featureAside h3,
.aside-group.featureAside h4,
.aside-group.featureAside h5,
.aside-group.featureAside h6 {
  color: #fff;
  left: 0; }

.aside-group.featureAside h2 .label {
  color: #FFFFC7; }

.aside-group.featureAside figure {
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding-bottom: 1em; }

.aside-group.aside-group.featureAside .spf-rce-section-content,
.aside-group.featureAside .spf-rce-section-content {
  background-color: transparent; }

article.assignment-content .bronte-styles .featureAside p a {
  color: #fff !important; }

.featureAside .keyword {
  Font-weight: bold; }

.featureAside div[type="key-term"] p span {
  color: #000; }

.featureAside div[type=“footnote”] p span {
  color: #000; }

/* Block Quote */
.blockquote p {
  font-family: "Sabon LT W05 Regular", serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.75em;
  color: #000; }

.blockquote p .footer {
  font-weight: 100; }

.blockquote {
  border-left: solid 6px #34b4c9;
  padding: 2em;
  margin: 2em 0 2em 0;
  background-color: #f7f7f7;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

/* Pull Quote */
.aside-group.pquote .blockquote {
  background-color: transparent;
  border-left: none; }

@container (width < 640px) {
  .aside-group.pquote .blockquote {
    padding-left: 0px;
    padding-right: 0px; } }

.aside-group.pquote {
  font-family: "Sabon LT W05 Regular", serif;
  font-size: 2em;
  margin-right: 2em;
  margin-left: 2em; }

.aside-group.pquote blockquote {
  padding-top: 0;
  padding-bottom: 0; }

.spf-rce-section.pquote .blockquote p {
  font-family: "Sabon LT W05 Regular", serif;
  font-size: 1.20em;
  line-height: 1.45em; }

@container (width < 640px) {
  .spf-rce-section.pquote .blockquote p {
    font-size: 0.90em; } }

.spf-rce-section.aside-group.pquote {
  margin-top: 0;
  margin-bottom: 0; }

.aside-group.aside-group.pquote .spf-rce-section-content {
  background-color: transparent; }

.pquote p {
  color: #46A1AF; }

/* Marginalia */
.aside-group.marginalia {
  background-color: #fff;
  margin: 4em auto 4em 20px;
  width: calc(100% - 20px); }

@container (width < 640px) {
  .aside-group.marginalia {
    margin-left: 10px; } }

.aside-group.marginalia p,
.aside-group.marginalia div.mathml-epub {
  border-left: 6px solid #98D831;
  padding: 1em 0 1em 1.5em;
  margin-bottom: -1em; }

.aside-group.marginalia p:first-child {
  margin-bottom: -0.5em; }

@container (width < 640px) {
  .aside-group.marginalia p {
    padding-left: 1.25em !important; } }

.marginalia .spf-element-text {
  padding: 0; }

/* Citation */
article.assignment-content .bronte-styles .citation {
  color: #000;
  margin: 0em 0em 1.70em 2.75em !important;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  letter-spacing: 0.01em;
  background: transparent;
  text-indent: -2.5em; }

div.citation {
  max-width: 100% !important;
  overflow-wrap: break-word; }

article.assignment-content .bronte-styles .citation ul p {
  font-size: 1em; }

/* --------------------------------- */
/* ------- Example 1 - Gray -------- */
/* --------------------------------- */
.spf-rce-section.example_1 {
  background-color: #f8f9fa;
  max-width: 940px;
  margin: 3em 0 3em 0;
  padding-bottom: 2em; }

.example_1 .spf-rce-section-content {
  background-color: transparent;
  margin: 0;
  padding-bottom: 2em; }

.example_1 h2 {
  font-size: 1.5em;
  font-weight: bold;
  left: -80px;
  top: 10px;
  background: transparent;
  margin: 0 0 2em 30px;
  padding: 1em 0 2px 49px;
  z-index: 4; }

.example_1 h2::after {
  content: "";
  position: absolute;
  background-color: #e2e5ea;
  left: -25px;
  height: calc(100% + 60px);
  top: -20px;
  z-index: -1;
  width: 940px; }

.example_1 h2::before {
  display: none; }

.example_1 span.label {
  color: #CC0300; }

.example_1 h3 {
  font-size: 1.25em;
  font-weight: 800;
  left: -80px;
  background: transparent;
  margin: 2em 0 1em 30px;
  padding: 0 0.15em 0 49px;
  z-index: 1; }

.example_1 h3::after {
  content: "";
  position: absolute;
  background-color: #e2e5ea;
  left: -25px;
  height: calc(100% + 26px);
  top: -12px;
  z-index: -1;
  width: 940px; }

/* ------ Authoring Misalignment Fix ------- */
.example_1 .spf-parallax-wrapper h2::after,
.example_1 .spf-parallax-wrapper h3::after {
  margin-left: 5px; }

/* ----------------------------------------- */
.example_1 .spf-rce-section-content {
  background-color: transparent;
  margin: 0;
  padding-bottom: 2em; }

.example_1 .spf-rce-section-background-wrapper {
  padding-top: 0px; }

/* Example Video MMI Code*/
.example_1 .mmi-video-modal h2::after {
  content: none; }

.example_1 .mmi-video-modal h2 {
  padding: 0;
  margin: 0; }

.aside-group.Sidebar_1Aside .mmi-video-modal h2,
.aside-group.Sidebar_2Aside .mmi-video-modal h2,
.aside-group.Sidebar_3Aside .mmi-video-modal h2,
.aside-group.Sidebar_4Aside .mmi-video-modal h2,
.aside-group.Sidebar_5Aside .mmi-video-modal h2,
.aside-group.Sidebar_6Aside .mmi-video-modal h2,
.aside-group.Sidebar_7aAside .mmi-video-modal h2,
.aside-group.Sidebar_7bAside .mmi-video-modal h2,
.aside-group.Sidebar_7cAside .mmi-video-modal h2,
.aside-group.Sidebar_7dAside .mmi-video-modal h2,
.aside-group.Sidebar_7eAside .mmi-video-modal h2,
.aside-group.Sidebar_7fAside .mmi-video-modal h2 {
  color: white;
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: normal; }

/* Audio video BV code */
.mediaPlayer .component-credit {
  color: #000; }

/* ------------------------------------ */
/* ----------- Example 2 & 3 ---------- */
/* ------------------------------------ */
.example_2,
.example_3 {
  background: transparent;
  margin: 3em 0 5em 0; }

@container (width < 640px) {
  .example_2,
  .example_3 {
    margin-top: 1em; } }

.spf-rce-stage .example_2 h2::before,
.spf-rce-stage .example_3 h2::before {
  content: "";
  position: absolute;
  background-color: #396fc0;
  height: 174px;
  width: 3px;
  top: 0;
  left: -80px; }

.example_2 h2::after,
.example_3 h2::after {
  content: "";
  position: absolute;
  background-color: #396fc0;
  height: 3px;
  width: calc(100% + 80px);
  top: 0;
  left: -80px; }

.example_2 h2,
.example_3 h2 {
  font-size: 2em;
  line-height: 1.15em;
  margin: 0.5em 0 0.125em 0;
  padding-top: 1.20em;
  color: #396fc0;
  left: 0;
  font-weight: bold; }

.example_2 span.label,
.example_3 span.label {
  color: #000; }

.example_2 h3 {
  font-size: 1.45em;
  font-weight: bold; }

.example_2 .spf-rce-row:last-child::after,
.example_3 .spf-rce-row:last-child::after {
  content: "";
  position: absolute;
  background: #396fc0;
  height: 3px;
  width: 97%;
  left: 0;
  bottom: -20px;
  margin-left: 10px; }

  @container (width < 960px) {
  .example_2 h2::before,
  .example_3 h2::before {
    display: none; } 
  .example_2 h2::after,
  .example_3 h2::after {
    content: "";
    position: absolute;
    background-color: #396fc0;
    height: 3px;
    width: 100%;
    top: 0;
    left: 0; } }

@container (width < 640px) {
  .example_2 h2,
  .example_3 h2 {
    font-size: 1.5em;
    margin-bottom: 0em;
    width: 100%; } }

.example_2 h2 .react-mathjax-preview-result,
.example_3 h2 .react-mathjax-preview-result {
  color: #396fc0; }

/* for Example 3 Only */
.example_3 h3 {
  font-size: 1.25em;
  font-weight: bold;
  padding: 0.125em 0.20em 0.175em 0.5625em;
  margin: 8px 0.125em -6px -12px;
  background-color: #eee;
  border-radius: 6px; }

.example_1 .mmi-video-modal h2::after {
  content: none; }

.example_1 .mmi-video-modal h2 {
  top: 0;
  margin: 0;
  padding-top: 0; }

.example_2 .mmi-video-modal h2 {
  color: #000;
  font-size: 1.5em;
  padding-top: 0; }

.example_2 .mmi-video-modal h2::after {
  content: none; }
/* Paragraph Indent */
body .paragraph-indent1 {
  margin-left: 70px !important; }

  @container (width < 425px) {
  body .paragraph-indent1 {
    margin-left: 40px !important; } }

.paragraph-indent1 .paragraph-indent1 {
  margin-left: 0 !important; }

/* Learning Objective Item */
.learningObjectiveItem p {
  position: relative;
  color: #595959;
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 1.125em;
  font-weight: bold;
  letter-spacing: 0.1em;
  left: -7.5vw;
  margin: 0; }

.learningObjectiveItem p,
.learningObjectiveItem span.number {
  font-family: "Slate W05 Regular", sans-serif;
  color: #595959;
  line-height: 1.5em; }

  @container (width < 1080px) {
  .learningObjectiveItem p {
    left: 0vw; } }

  @container (width < 640px) {
  .learningObjectiveItem p {
    font-size: 1.0275em;
    left: 0vw; } }

.learningObjectiveItem .learningObjectiveItem {
  border: none;
  padding-left: 0; }

/* Learning Objective Item 2 */
.learningObjectiveItem2 {
  padding-left: 1.25em;
  border: 1px solid #aaa;
  margin: 1em 10px 1em 10px !important; }

.learningObjectiveItem2 p {
  max-width: 800px;
  font-family: "Slate W05 Regular", sans-serif;
  line-height: 1.60em;
  margin: 5px 0.25em 5px 0.5em !important; }

  @container (width < 475px) {
  .learningObjectiveItem2 .number {
    padding-left: 0 !important; } }

    @container (width < 640px) {
  .learningObjectiveItem2 p {
    line-height: 1.45em; } }

.learningObjectiveItem2 .number {
  font-family: "Slate W05 Regular", sans-serif;
  color: #000;
  text-align: left;
  margin: 0 auto 0 auto;
  font-weight: bold;
  text-decoration: none !important;
  margin-right: .55em !important; }

/* Needed for Authoring */
.spf-parallax-wrapper .learningObjectiveItem2 {
  margin-left: unset !important;
  margin-right: 0 !important;
  padding-top: 6px;
  padding-bottom: 6px; }

.spf-parallax-wrapper .learningObjectiveItem2 p {
  margin-left: 0 !important;
  margin-right: 0.5em !important; }

/* -------------------- */
/* Learning Objective Set */
.objectiveset {
  border: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  margin: 4em 0 4em 0;
  padding: 36px 10px 10px 10px;
  max-width: 800px; }

  @container (width < 1024px) {
  .spf-rce-section.objectiveset {
    padding: 50px 0 10px 0;
    width: 95%; } }

    @container (width < 640px) {
  .objectiveset {
    border: none;
    margin-top: 0; } }

    @container (width < 640px) {
  .objectiveset {
    margin: 1em 0 2em 0;
    padding: 0 0 0 0;
    max-width: 100%;
    border: unset; } }

    @container (width < 475px) {
  .spf-rce-section.objectiveset {
    padding: 0px 0 10px 0;
    width: 95%; } }

.objectiveset .spf-rce-section-background-wrapper {
  min-width: unset; }

.objectiveset h2 {
  font-family: "Sabon LT W05 Regular", sans-serif;
  font-size: 1.55em;
  font-weight: bold;
  letter-spacing: 0.4px;
  word-break: normal;
  margin-top: 0;
  left: -0.5em;
  color: #CC0300; }

  @container (width < 640px) {
  .objectiveset h2 {
    font-size: 1.35em;
    left: 0;
    margin-bottom: 0; } }

.objectiveset h3 {
  margin-top: unset; }

.objectiveset .spf-rce-section-content {
  background-color: transparent;
  min-width: unset; }

.objectiveset h2::before {
  display: none; }

.objectiveset p {
  font-family: "Slate W05 Regular", sans-serif;
  line-height: 1.65em;
  padding: 0; }

.objectiveset ol {
  padding: 0; }

.objectiveset ul {
  padding: 0;
  margin-left: 0.25em; }

.objectiveset ul li div a {
  color: #000; }

.objectiveset .ul_none ul li p span {
  font-family: "Slate W05 Regular", sans-serif;
  color: #000;
  text-decoration: none; }

.objectiveset ul li {
  font-family: "Slate W05 Regular", sans-serif;
  list-style: none;
  margin: 0 0 1em 0;
  color: #162030;
  text-decoration: none; }

.objectiveset .number {
  font-family: "Slate W05 Regular", sans-serif;
  margin-right: 24px;
  position: absolute;
  color: #000;
  text-align: right;
  margin: 0 0 1em -5em;
  width: 4.5em;
  font-weight: bold;
  text-decoration: none !important; }

  @container (width < 640px) {
  .objectiveset ul {
    margin-left: 0em !important; } }

    @container (width < 475px) {
  .objectiveset ul {
    margin-left: -1.5em !important; } }

    @container (width < 320px) {
  .objectiveset ul {
    margin-left: -2em !important; } }

    @container (width < 425px) {
  .objectiveset ul .number,
  .objectiveset .ul_none ul .number {
    margin-left: -4.75em !important; } }

/* for Authoring ul_none in Objective Set */
.objectiveset .spf-parallax-wrapper .ul_none .number {
  margin-left: -5em; }


/* ----------------------------- */
/* Hands-On Exercise */
.hands-on-ex {
  margin: 0em 0 3em 0;
  padding-top: 1em;
  padding-bottom: 2.5em;
  background-color: #FAFAD8; }

@container (width < 640px) {
  .hands-on-ex {
    padding-top: 0; } }

.hands-on-ex h1 {
  color: #CC0300; }

.hands-on-ex h2 {
  font-size: 2.0em;
  margin-top: 0.5em; }

@container (width < 640px) {  
  .hands-on-ex h2 {
    font-size: 1.45em; } }

.hands-on-ex h2 span.label {
  color: #CC0300;
  font-size: 2.0em; }

body .spf-rce-stage .hands-on-ex h2::before {
  display: none; }

.hands-on-ex h3 {
  letter-spacing: 0.05em; }

.hands-on-ex h3 span.label {
  color: #CC0300;
  font-weight: bold;
  letter-spacing: 0.05em; }

.hands-on-ex .spf-rce-section-content {
  background-color: unset; }

/* Padding to reduce text column width for Mobile */
@container (width < 640px) {
  .spf-rce-section-content {
    padding: 0px 10px !important; } }

.ul_none li .label {
  color: #CC0300; }

.label.spf-inline-span:hover {
  background-color: transparent; }


/* Part Intro */
article.assignment-content .bronte-styles .partNumber h1 {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 2em;
  font-weight: bold; }

article.assignment-content .bronte-styles .partTitle h1 {
  font-size: 3em; }

.partNumber h1::before,
.partTitle h1::before {
  content: none; }

/* Chapter Intro */
.chapterTitle h1 {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 65px;
  line-height: 1.25em;
  letter-spacing: 1px;
  color: #fff;
  z-index: 5;
  top: -10.75em;
  left: -12vw;
  position: absolute;
  max-width: 820px; }

  @container (width < 1080px) {
    .chapterTitle h1 {
      left: -4vw; } }
  
  @container (width < 640px) {
    .chapterTitle h1 {
      font-size: 2.65em;
      line-height: 1.40em;
      top: -9em;
      left: 0; } }
.chapterNumber h1::before {
  content: none; }
.chapterNumber h1 {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 27px;
  letter-spacing: 2px;
  color: #fff !important;
  z-index: 4;
  top: -25.80em;
  left: -12vw;
  position: absolute;
  display: block;
  margin-bottom: 1em;
 }

h1 .chapterNumber {
  font-weight: bold;
  color: #fff !important;
  text-transform: none !important; }
  @container (width < 1080px) {
    .chapterNumber h1 {
      left: -4vw; } }
  
      @container (width < 640px) {
    .chapterNumber h1 {
      font-size: 1.25em;
      top: -18.75em;
      left: 0; } }
  
      @container (width < 475px) {
    .chapterNumber h1 {
      margin-bottom: 0.5em; } }
  
  .chapterNumber h1 .number::after {
    content: none; }
  
  .chapterTitle h1::before {
    content: none; }

div.chapterIntro {
  display: block;
  margin-bottom: 4em;
  width: 100%;
  margin-top: 0;
  padding-left: 0; }

  @container (width < 640px) {
    div.chapterIntro {
      margin-bottom: 4em; } }

      .chapterIntro figure {
        position: relative;
        margin-top: -1em;
        width: 100%;
        max-height: 760px !important;
        transition: opacity 0.7s ease 0s; }
      
      .chapterIntro img {
        height: 760px !important;
        max-height: 760px !important;
        object-fit: cover !important;
        top: 0;
        min-width: 100.5vw;
        max-width: none;
        margin-left: 0 !important;
        margin-right: 0 !important; }
      
    @container (width < 640px) {
        .chapterIntro img {
          min-width: 101vw;
          width: 101vw;
          max-height: 370px !important;
          height: 370px !important;
          left: unset; } }
      
      .chapterIntro figure:after {
        content: '';
        position: absolute;
        background: linear-gradient(to top, rgba(25, 25, 25, 0) 0, rgba(25, 25, 25, 0.8) 100%);
        z-index: 0;
        top: 0;
        bottom: 0;
        left: -65em;
        right: 0;
        width: 2800px;
        max-width: 2800px;
        height: 760px;
        max-height: 760px;
        transition: opacity 0.7s ease 0s; }
      
      @container (width < 640px) {
        .chapterIntro figure:after {
          background: linear-gradient(to top, rgba(25, 25, 25, 0) 0, rgba(25, 25, 25, 0.8) 100%);
          height: 370px;
          max-height: 370px; } }
      
          @container (width < 475px) {
        .chapterIntro figure:after {
          height: 280px;
          max-height: 280px; } }
      
          @container (width < 640px) {
        .chapterIntro figure {
          max-height: 480px !important; } }
      
          @container (width < 475px) {
        .chapterIntro figure {
          max-height: 320px !important; } }
      
      .chapterIntro figcaption {
        max-width: 26em;
        margin-top: 0;
        padding: 2.30em 1.70em 1em 1.45em;
        position: absolute !important;
        background-color: rgba(25, 25, 25, 0.6);
        left: -12vw;
        bottom: 6em;
        z-index: 5; }
      
        @container (width < 1080px) {
        .chapterIntro figcaption {
          left: -4vw; } }
      
      @container (width < 640px) {
        .chapterIntro figcaption {
          max-width: none;
          background-color: transparent;
          padding-top: unset;
          padding-right: 0;
          padding-bottom: unset;
          padding-left: 0;
          left: 0;
          top: 24em; } }
      
      .hidecaption figcaption {
        visibility: hidden; }
      
      .chapterIntro figcaption p {
        color: #fff; }
      
      .chapterIntro figcaption p:first-child {
        margin-top: -.75em !important;
        margin-bottom: 0.5em;
        color: #fff;
        border-top: solid 2px #CC0300;
        padding-top: 1em;
        font-size: 1em; }
      
      @container (width < 640px) {
        .chapterIntro figcaption p:first-child {
          border-top: unset;
          color: #000 !important;
          font-size: 0.75em !important;
          line-height: 1.3125em;
          padding: unset;
          margin-top: unset !important; } }
      
      .chapterIntro figcaption p {
        color: #fff !important;
        margin-top: 0.5em !important;
        font-size: .85em; }
      
      .chapterIntro p a {
        color: #fff !important; }
      
      @container (width < 640px) {
        .chapterIntro figcaption p {
          font-size: 1.25em;
          color: #000 !important;
          font-size: 0.65em !important;
          padding: unset; } }
      
      div.chapterIntro .spf-rce-section-content .spf-rce-element {
        padding-left: unset; }
      
      .chapterIntro .spf-rce-section-background-wrapper div {
        height: unset !important; }
      
      .chapterIntro .layout {
        overflow: visible; }
      
      .chapterIntro .spf-element-image {
        overflow: visible; }
      
      .chapterIntro .ezPZI.before-transition {
        opacity: unset; }
      
        @container (width < 475px) {
        .chapterTitle h1 {
          font-size: 2.125em;
          top: -1em;
          left: 5px;
          max-width: 440px;
          margin-left: 0px;
          position: relative;
          color: #000 !important;
          margin-bottom: -1em; } }
      
          @container (width < 425px) {
        .chapterTitle h1 {
          top: -.3em;
          left: 5px;
          margin-left: 10px;
          position: relative;
          color: #000 !important; } }
      
          @container (width < 320px) {
        .chapterTitle h1 {
          font-size: 25px;
          top: -2em;
          left: -5px;
          max-width: 440px;
          margin-left: 10px; } }
      
          @container (width < 475px) {
        .chapterNumber h1 {
          left: -5px;
          margin-left: 10px;
          font-size: 1em;
          top: .5em;
          position: relative;
          color: #000 !important; } 
        h1 .chapterNumber {
          color: #000 !important; } 
        div.chapterIntro {
          margin-top: 0em;
          border-top: 50px solid #CC0300;
          margin-bottom: 0em; } 
        .chapterIntro figcaption p {
          font-size: 12px !important;
          margin-top: 0.25em; } 
        div.chapterIntro img {
          object-fit: cover !important;
          top: -1em;
          min-width: 100vw;
          max-width: none;
          position: relative;
          margin-left: 0 !important;
          margin-right: 0 !important; } 
        .chapterIntro figcaption {
          font-size: .85em;
          max-width: none;
          background-color: unset;
          padding-top: unset;
          padding-right: 0;
          padding-bottom: unset;
          padding-left: 0;
          left: 0;
          top: 0em;
          position: relative !important; } 
        .chapterIntro figure:after {
          top: -1em; } }
      
      .chapterIntro .pearson-component.longdescontent {
        display: none; }
      
      .chapterIntro h1 code {
        color: #fff;
        font-size: 1em; }
      
        @container (width < 475px) {
        .chapterIntro h1 code {
          color: #000;
          font-size: 1em; } }

/* --------------------------------------------------  */
/* ----------------- Table Component ----------------  */
/* --------------------------------------------------  */
@media screen and (max-width: 640px) {
  div.spf-element-table {
    overflow-x: scroll; } }

.bronte-styles table {
  margin-bottom: 0.5em;
  border: 1px solid #777 !important; }

/* for authoring */
.spf-element-table .component-label span p {
  font-family: "Slate W05 Regular", sans-serif;
  color: #CC0300 !important;
  font-size: 1.0em;
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: 1px;
  fill: #CC0300 !important; }

/* ------------- */
.spf-element-table span.component-title {
  font-family: "Slate W05 Regular", sans-serif;
  font-weight: normal;
  display: block;
  color: #000 !important;
  width: 100%;
  font-size: 0.93em;
  line-height: 1.5em;
  letter-spacing: 0;
  margin-bottom: 0.125em; }

/* for authoring */
.spf-element-table span.component-title p {
  margin-bottom: 0.5em;
  fill: #000; }

/* ------------- */
.spf-element-table .component-instruction {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 1.15em;
  font-weight: normal;
  line-height: 1.25em;
  color: #4f4f4f !important;
  margin-top: 0.5em; }

  .spf-element-table .component-caption {
    font-family: "Slate W05 Regular", sans-serif;
    font-size: 1.0125em;
    color: #000;
    width: 100%;
    line-height: 1.4em;
    font-style: normal;
    text-align: left;
    margin-bottom: 0.25em; }

    .spf-element-table .component-credit {
      font-family: "Slate W05 Regular", sans-serif;
      font-size: 0.9375em;
      line-height: 1.45em;
      font-style: normal;
      text-align: left;
      color: #000;
      width: 100%;
      margin: 0; }

table.table-gridline-horizontal td {
  border: 1px solid #000 !important;
  border-right: none !important;
  border-left: none !important; }

table.table-gridline-vertical td {
  border: 1px solid #000 !important;
  border-top: none !important;
  border-bottom: none !important; }

table.table-gridline-none td {
  border: 1px solid #000 !important;
  border: none !important; }

  p.table-title,
  caption.table-title p {
    font-family: "Sabon LT W05 Regular", serif;
    color: #000 !important;
    font-size: 1.15em;
    font-weight: bold;
    line-height: 1.5em;
    padding: 0.25em 1em 0em 1em;
    letter-spacing: 1px; }

caption p {
  font-size: 1.15em;
  line-height: 1.5em; }

table caption {
  border: 1px solid #777 !important;
  background-color: #fff;
  margin-bottom: -1px; }

p.table-caption {
  font-family: "Sabon LT W05 Regular", serif;
  color: #000 !important;
  font-size: 1.15em;
  font-weight: normal;
  line-height: 1.25em;
  padding: 0.125em 1em 0.25em 1em;
  letter-spacing: 1px; }

.spf-parallax-wrapper caption {
  border: none !important; }

th,
thead tr {
  background-color: #ddd; }

  table th {
    font-family: "Sabon LT W05 Regular", serif;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.5em;
    padding: 0.75em !important;
    border: 1px solid #000; }
  
  table td {
    font-family: "Sabon LT W05 Regular", serif;
    font-size: 1em;
    line-height: 1.5em;
    vertical-align: top;
    border: 1px solid #000; }
  
  table th p {
    font-size: 1em;
    line-height: 1.5em; }
  
  table td p {
    font-family: "Sabon LT W05 Regular", serif;
    font-size: 1em;
    line-height: 1.5em;
    padding: 0.35em;
    vertical-align: top; }

caption.belowTable {
  background-color: transparent; }

table div.component-caption p {
  margin-bottom: 1em; }

.spf-element-table {
  margin-bottom: 3em !important; }

/* Header colors for Table Dark, Light, None */
table th,
table thead tr {
  background-color: #ddd; }

table.noColor th,
table.noColor thead tr {
  background-color: #fff; }

table.light th,
table.light thead tr {
  background-color: #ddd; }

table.dark th,
table.dark thead tr {
  background-color: #CC0300; }

/* Font color in table header row and column for table Dark */
table.dark tr th p {
  color: #fff; }

/* Header Gridline color in dark mode */
table.dark th {
  border: 1px solid #FFAFAD; }

table th a {
  color: #CC0300; }

table th a.keyword {
  color: #CC0300 !important; }


  table {
    margin: 0 auto 0em auto !important;
    border-collapse: collapse;
    border: 1px solid #000 !important;
    width: unset !important;
    min-width: min-content; }

/* Show Hide */
div .spf-show-hide-tab {
  color: #CC0300;
  background-color: transparent;
  font-size: 1.125em; }

div.bar-line {
  background-color: #CC0300 !important; }

div.spf-show-hide-tab:hover {
  color: black !important; }

  .collapsible-config-submenu {
    color: #CC0300 !important; }
  
  .collapsible-config-submenu .expand-collapse {
    border: solid #CC0300 !important;
    border-width: 0px 2px 2px 0 !important; }
  
  .collapsible-wrapper[data-format="vertical"] {
    border-left-color: #CC0300 !important; }
  
  .spf-inline-reference-link {
    color: #CC0300; }

.spf-collapsible-text-box {
  white-space: unset !important; }

div label.player-label-left {
  color: #CC0300;
  font-weight: bold;
  display: block; }

.displaytitle p {
  color: #CC0300;
  font-weight: bold;
  display: block;
  letter-spacing: 0em;
  font-size: 1em;
  font-family: "Slate W05 Regular", sans-serif;
  width: 100%;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  fill: #CC0300; }
  .displaytitle p:after {
    content: '';
    position: absolute;
    background-color: #CC0300;
    width: 2.80em;
    height: 2px;
    left: .1px;
    margin-top: -.45em; }
    h4.label {
      top: -1em; }

div.left h4.label p {
  color: #000;
  font-family: "Slate W05 Regular", sans-serif;
  width: 100%;
  font-size: 1em;
  line-height: 1.7em;
  font-weight: 100;
  letter-spacing: 0em;
  margin-bottom: -.8em; }

p.caption {
  font-size: 14px; }

  div .left .caption p {
    font-family: "Slate W05 Regular", sans-serif;
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    line-height: 1.4em;
    text-align: left;
    color: #000;
    margin: 0;
    padding: 0 0 5px 0; }

p.credit {
  font-size: 14px; }

div.left .credit p {
  font-size: .85em;
  font-style: normal;
  text-align: left;
  color: #000;
  width: 100%;
  margin-top: 0.1em;
  margin-bottom: 1em;
  line-height: 0.5em; }

/* Video MMI Code Platform */
div .divVideo h4 {
  font-size: .95em;
  font-weight: 400;
  font-family: "Neue Haas Unica W05 Regular", sans-serif; }

figure.figureVideo h4 p {
  font-size: 1.4em; }

div.featureAside h4.heading4VideoTitle p {
  color: #fff !important; }

h4.heading4VideoTitle p {
  color: #CC0300 !important;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  max-width: 680px;
  font-weight: bold !important;
  width: auto;
  margin-bottom: .5em;
  padding: 0;
  font-size: 1.2em !important;
  font-weight: 400;
  line-height: 1.45em;
  margin-bottom: -.5em;
  fill: #CC0300 !important; }

h4.heading4VideoNumberLabel p {
  color: #000;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  max-width: 680px;
  width: auto;
  font-size: 1.1em !important;
  line-height: 1.5em;
  letter-spacing: 1.3px;
  font-weight: 500 !important;
  fill: #000; }

figcaption.figcaptionVideo {
  font-size: 1em;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  padding-top: 0.5em;
  width: 100%; }

p.paragraphVideoCredit {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: .7em;
  padding-top: 0.5em;
  width: 100%; }

div.divVideo .paragraphVideoCredit p {
  font-weight: normal;
  font-style: normal;
  line-height: 1.4;
  text-align: left;
  color: #162030;
  margin: 0;
  padding: 0 0 5px 0;
  font-size: .7em; }

/* Audio MMI Code Platform */
div .divAudio h4 {
  font-size: .95em;
  font-weight: 400;
  font-family: "Neue Haas Unica W05 Regular", sans-serif; }

figure.figureAudio h4 p {
  font-size: 1.1em; }

div.featureAside h4.heading4AudioTitle p {
  color: #fff !important; }

h4.heading4AudioTitle p {
  color: #CC0300 !important;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  max-width: 680px;
  font-weight: bold !important;
  width: auto;
  margin-bottom: .5em;
  padding: 0;
  font-size: 1.2em !important;
  font-weight: 400;
  line-height: 1.45em;
  margin-bottom: -.5em;
  fill: #CC0300 !important; }

h4.heading4AudioNumberLabel p {
  color: #000;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  max-width: 680px;
  width: auto;
  font-size: 1.1em !important;
  line-height: 1.5em;
  letter-spacing: 1.3px;
  font-weight: 500 !important;
  fill: #000; }

figcaption.figcaptionAudio {
  font-size: 1em;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  padding-top: 0.5em;
  width: 100%; }

p.paragraphAudioCredit {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-size: .7em;
  padding-top: 0.5em;
  width: 100%; }

div.divAudio .paragraphAudioCredit p {
  font-weight: normal;
  font-style: normal;
  line-height: 1.4;
  text-align: left;
  color: #162030;
  margin: 0;
  padding: 0 0 5px 0;
  font-size: .7em; }

.spf-collapsible-text-box {
  white-space: unset !important; }

/* Multi-Colum Responsive */
@container (width < 640px) {
  .spf-rce-row {
    display: block !important; } }

/* Component Styles */
/* NEW Universal Component Styles */
.spf-element-text, .spf-element-image {
  position: relative;
  padding: 10px;
}

.spf-element-image h4 span.component-label,
.spf-element-image span.component-label {
  font-family: "Slate W05 Regular", sans-serif;
  color: #CC0300 !important;
  font-size: 1.0em;
  line-height: 1.20em;
  font-weight: bold;
  margin-bottom: 0.15em;
  letter-spacing: 1px;
  position: relative;
  display: block; }
  .spf-element-image h4.component-label:before {
    content: '';
    position: absolute;
    background-color: #CC0300;
    width: 2.80em;
    height: 2px;
    margin-top: -0.30em; }
    /* Needed for Image Component Authoring */
.spf-element-image h4.component-label p {
  font-family: "Slate W05 Regular", sans-serif;
  color: #CC0300;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: 1px; }

.spf-element-image span.component-label p {
  font-family: "Slate W05 Regular", sans-serif;
  color: #CC0300;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: 1px; }

.spf-element-image h4 span.component-label:before {
  content: '';
  position: absolute;
  background-color: #CC0300;
  width: 2.80em;
  height: 2px;
  margin-top: -0.30em; }

.bronte-styles .spf-element-image h4 span.component-title,
.bronte-styles .spf-element-image span.component-title {
  font-family: "Slate W05 Regular", sans-serif;
  font-weight: normal;
  display: block;
  color: #000 !important;
  width: 100%;
  font-size: 0.90rem;
  line-height: 1.20em;
  letter-spacing: 0;
  margin-bottom: 0.45em; }
  .bronte-styles .spf-element-image span.component-title p,
  .bronte-styles .spf-element-image span.component-title p strong {
    font-family: inherit;
  }

div.question-prompt p {
  color: #000; }

/* ------------------ */
.bronte-styles .spf-element-image p.component-caption,
.bronte-styles  .spf-element-image .component-caption p {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 1.0em;
  color: #000;
  width: 100%;
  line-height: 1.4em;
  font-style: normal;
  text-align: left;
  margin-top: 0.35em; }

.spf-element-image p.component-credit {
  font-family: "Slate W05 Regular", sans-serif;
  font-size: 0.95em;
  line-height: 1.35em;
  font-style: normal;
  text-align: left;
  color: #000;
  width: 100%;
  margin: 0; }

.spf-element-image figcaption {
  margin: 0 0 0.125em 0; }

/* Needed for Image Component Authoring */
.spf-element-image figcaption.styles__StyleCaption-sc-1c6ifcu-2 {
  font-size: 1em; }

.component-credit {
  padding-top: unset !important;
  width: 800px; }

.component-wrapper .component-label,
.component-wrapper .component-instruction {
  margin-left: 0; }
  @container (width < 768px) {
    div.spf-element-image {
      overflow: auto; } }



/* End Universal Component Styles */
/* MCQ */
iframe.plugin-etext-mcq {
  margin-left: -5px;
  max-width: 800px; }

fieldset.mcq-container {
  margin-top: 2em;
  padding: 0px 10px !important; }

label.option.etext-choice p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20px; }

label.option p {
  margin-bottom: 0;
  margin-top: 0;
  line-height: 20px; }

.button-row {
  text-align: center; }

.button-row button {
  background-color: #1F6FEF !important; }

.button-row button:hover,
.button-row button:focus {
  background-color: #1F6FEF !important; }

.feedback-icon.correct {
  line-height: 1em;
  background-color: #01b37e !important; }

.feedback-icon.incorrect {
  line-height: 1em;
  background-color: #ED5007 !important; }

.question-prompt.etext-question {
  line-height: 2em; }

div.aside-group main.etext-question {
  padding-left: 1.5em; }

div.response.correct .response-header p {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-weight: 600;
  color: #01b37e; }

div.response.incorrect .response-header p {
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  font-weight: 600;
  color: #ED5007; }

div.response.correct {
  background-color: #f7f7f7 !important;
  border-left: 4px solid #01b37e;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px; }

div.response.incorrect {
  background-color: #f7f7f7 !important;
  border-left: 4px solid #ED5007;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px; }

.response.display.correct p,
.response.display.incorrect p {
  color: #000; }

.Sidebar_1Aside div.response.correct,
.Sidebar_1Aside div.response.incorrect {
  background-color: #fff !important; }

.answer-options input.selector:checked:before {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  background: #377E8B !important; }

/* Flashcard */
div.spf-rce-element.spf-element-plugin {
  margin-top: 1em;
  margin-bottom: 1em; }

.cards-container .card {
  margin-left: 0 !important; }

.plugin-Flashcard-Widget .card-container .back .inner p {
  font-size: 1.30rem !important;
  color: #000 !important; }

.plugin-Flashcard-Widget .card-container .front .inner p {
  font-size: 1.75rem !important;
  color: #007E9E !important; }

.plugin-Flashcard-Widget .navigation-mininav-wrapper .container {
  background-color: #CC0300; }

.plugin-Flashcard-Widget .navigation-mininav-wrapper .container .controls .page {
  border: none;
  border-bottom: 0;
  border-top: 0; }

.plugin-Flashcard-Widget .card-container section.front.cover,
.plugin-Flashcard-Widget .card-container section.back.cover {
  background: rgba(0, 0, 0, 0.1) !important; }

.spf-element-image figcaption {
  margin-bottom: 0.125em; }

/* Needed for Image Component Authoring */
.spf-element-image figcaption.styles__StyleCaption-sc-1c6ifcu-2 {
  font-size: unset; }

/* End Image Component Styles */
/* iFrame Component Styles */
.iframe-container {
  /* max-width: 800px !important; */
  padding-left: 10px !important;
  margin: 2em 0 2em 0; }

p.component-caption {
  margin: -0.5em 0 0 0; }

p.component-credit {
  margin: 0 0 0.5em 0; }

/*Bronte Authoring*/
.blockquote .blockquote {
  border-left: none !important;
  padding: 0 !important;
  margin: 0 !important; }

.learningObjectiveItem .learningObjectiveItem {
  border: none;
  padding-left: 0; }

.paragraph-indent1 .paragraph-indent1 {
  margin-left: 0 !important; }

.citation .citation {
  margin-left: 0 !important; }

.styles__Menu-grhvnq-0.cACInP.sc-dVhcbM.bAxfad {
  background-color: #232c39 !important; }

.SelectionMenuWrapper-sc-1ww5eng-0.sc-jqCOkK.cRmgws {
  background-color: #263142 !important; }

.styles__Menu-grhvnq-0.cACInP.styles__StyledMenuWrapper-jwxwn8-3.emDFwA {
  background-color: #232c39 !important; }

.styles__Menu-grhvnq-0.ifaXoU.styles__StyledMenuWrapper-jwxwn8-3.ixYAjR {
  background-color: #232c39 !important; }

.styles__Menu-grhvnq-0.jqAjff.styles__StyledMenuWrapper-jwxwn8-3.ixYAjR {
  background-color: #232c39; }

.SelectionMenuWrapper-sc-1ww5eng-0.sc-htoDjs.iqNDzL {
  background-color: #263142; }

/* --------------------------------------------------  */
/* ---------- Primary & Secondary Content 1 ---------  */
/* ----------------- Text and Image -----------------  */
/* --------------------------------------------------  */
.primary_secondary_1 .spf-rce-row {
  display: flex;
  left: -13em;
  column-gap: 3px; }

.primary_secondary_1 .spf-rce-column:first-child {
  background-color: transparent;
  padding: 0;
  border-radius: 18px 0 0 18px;
  flex: 1 0 calc(25% - 0px) !important;
  height: fit-content;
  top: 0.5em; }

.primary_secondary_1 .spf-rce-column:first-child p {
  font-size: .95em;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  line-height: 1.5em; }

.primary_secondary_1 .spf-rce-column:nth-child(2) {
  flex: 1 0 calc(100% + 0px) !important; }

.primary_secondary_1 .spf-rce-column:first-child p {
  font-size: .95em;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  line-height: 1.5em; }

  @container (width < 1024px) {
  .primary_secondary_1 .spf-rce-column:first-child p {
    font-size: inherit;
    font-family: "Neue Haas Unica W05 Regular", sans-serif;
    line-height: 1.5em; } 
  .primary_secondary_1 .spf-rce-row {
    flex-wrap: wrap;
    flex-direction: row;
    width: auto;
    left: unset; } }

.primary_secondary_1 .spf-element-image h4,
.primary_secondary_2 .spf-element-image h4 {
  margin-top: 0; }

.primary_secondary_1 .spf-element-image h4 span.component-label,
.primary_secondary_2 .spf-element-image h4 span.component-label {
  margin-bottom: 0.40em; }

.primary_secondary_1 .spf-element-image h4 span.component-title,
.primary_secondary_2 .spf-element-image h4 span.component-title {
  font-size: 0.85em;
  line-height: 1.30em;
  margin-bottom: 1.25em; }

/* --------------------------------------------------  */
/* ---------- Primary & Secondary Content 2 ---------  */
/* -------------- Boxed Text and Image --------------  */
/* --------------------------------------------------  */
.primary_secondary_2 .spf-rce-row {
  display: flex;
  left: -14em;
  column-gap: 3px; }

.primary_secondary_2 .spf-rce-column:first-child {
  background-color: #eee;
  padding: 0.5em;
  flex: 1 0 calc(25% - 0px) !important;
  border-radius: 12px 0 0 12px;
  height: fit-content;
  top: 1.40em; }

  @container (width < 1080px) {
  .primary_secondary_2 .spf-rce-column:first-child {
    background-color: unset;
    height: fit-content;
    top: unset; } }

.primary_secondary_2 .spf-rce-column:first-child p {
  font-size: .95em;
  font-family: "Neue Haas Unica W05 Regular", sans-serif;
  line-height: 1.5em; }

.primary_secondary_2 .spf-rce-column:nth-child(2) {
  flex: 1 0 calc(100% + 0px) !important; }

  @container (width < 1024px) {
  .primary_secondary_2 .spf-rce-row {
    flex-wrap: wrap;
    flex-direction: row;
    width: auto;
    left: unset; } }

.primary_secondary_1 .spf-rce-column:first-child h4.component-label,
.primary_secondary_2 .spf-rce-column:first-child h4.component-label {
  margin: 0em 0 0.25em 0; }

/* Callout Block 1 Styles for any Secondary Column */
.callout {
  top: 0.5em; }

.callout p {
  line-height: 1.5em;
  text-align: right;
  color: #444; }

.callout_header p {
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  position: absolute;
  border-bottom: 1px solid #666;
  text-align: right;
  right: 0; }

.callout_header {
  font-size: 1.0675em;
  top: 0.25em;
  padding-bottom: 28px !important; }

.callout_subhead p {
  text-align: right;
  color: #444;
  position: absolute;
  display: block;
  right: 0;
  padding-right: 4px; }

.callout_1a_header,
.callout_1b_header,
.callout_1c_header {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 3px 0 !important; }

.callout_1a_header p,
.callout_1b_header p,
.callout_1c_header p {
  font-size: 1.0375em !important;
  font-weight: 600;
  text-align: right;
  letter-spacing: 1px;
  position: relative;
  border-radius: 0 9px 0 0;
  padding: 0.4em 0.5em 0.35em 0.35em !important;
  margin-bottom: 0;
  line-height: 1.25em; }

.callout_1a_header p {
  color: #3673CE;
  border-top: solid 1px #3673CE;
  border-right: solid 4px #3673CE; }

.callout_1b_header p {
  color: #6D558B;
  border-top: solid 1px #6D558B;
  border-right: solid 4px #6D558B; }

.callout_1c_header p {
  color: #008080;
  border-top: solid 1px #008080;
  border-right: solid 4px #008080; }

/* for authoring */
.spf-parallax-wrapper div.callout_1a_header,
.spf-parallax-wrapper div.callout_1b_header,
.spf-parallax-wrapper div.callout_1c_header,
.spf-parallax-wrapper div.callout_2a_header,
.spf-parallax-wrapper div.callout_2b_header,
.spf-parallax-wrapper div.callout_2c_header {
  display: unset; }

/* ------------- */
.callout_1_subhead {
  padding-top: 0 !important; }

.callout_1_subhead p {
  font-family: "Sabon LT W05 Regular", serif !important;
  font-size: 1em !important;
  font-style: italic;
  text-align: right;
  line-height: 1.35em;
  color: #444;
  display: block;
  margin: 0.25em 0.25em 1em 0; }

  @container (width < 1080px) {
  .callout_1a_header,
  .callout_1b_header,
  .callout_1c_header {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.65em !important; } 
  .callout_1a_header p,
  .callout_1b_header p,
  .callout_1c_header p {
    text-align: left;
    border-top: unset;
    border-right: unset;
    padding: unset !important; } 
  .callout_1_subhead p {
    text-align: left;
    margin-bottom: 0; } }

/* Callout Block 2 Styles for any Secondary Column */
.callout_2a_header,
.callout_2b_header,
.callout_2c_header {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 6px 0 !important;
  top: -1px; }

.callout_2a_header p,
.callout_2b_header p,
.callout_2c_header p {
  font-size: 1.0375em !important;
  font-weight: 600;
  text-align: right;
  letter-spacing: 1px;
  position: relative;
  border-bottom: 1px solid #666;
  padding: 0.4em 0em 0.35em 0em !important;
  margin-bottom: 0;
  line-height: 1.25em; }

.callout_2a_header p {
  color: #3673CE; }

.callout_2b_header p {
  color: #6D558B; }

.callout_2c_header p {
  color: #008080; }

.callout_2_subhead {
  padding: 0 !important;
  top: -2px; }

.callout_2_subhead p {
  font-family: "Sabon LT W05 Regular", serif !important;
  font-size: 1em !important;
  text-align: right;
  line-height: 1.35em;
  color: #444;
  display: block;
  margin: 0.25em 0.25em 1em 0; }

  @container (width < 1080px) {
  .callout_2a_header,
  .callout_2b_header,
  .callout_2c_header,
  .callout_2_subhead {
    display: flex;
    justify-content: flex-start;
    padding-left: 0.65em !important; } 
  .callout_2a_header p,
  .callout_2b_header p,
  .callout_2c_header p {
    text-align: left;
    border-bottom: unset;
    padding: unset !important; } }

/*Wider than Text */
.wider_text .spf-rce-section-content {
  max-width: 1200px; }

  @container (width < 1200px) {
  .wider_text .spf-rce-section-background-wrapper .spf-rce-section-content {
    max-width: 1000px !important; } }

.wider_text .spf-rce-section-background-wrapper {
  max-width: 1200px; }

.wider_text_2 .spf-rce-section-content {
  max-width: 1300px; }

.wider_text_2 .spf-rce-section-background-wrapper {
  max-width: 1300px; }

  @container (width < 1200px) {
  .wider_text_2 .spf-rce-section-background-wrapper .spf-rce-section-content {
    max-width: 1050px !important; } }

/* Wider than Text Worked Example 1 */
.wider_example_1 .spf-rce-section-content {
  max-width: 1300px; }

.wider_example_1 {
  background: transparent;
  margin-top: 3em;
  margin-bottom: 6em; }

  @container (width < 640px) {
  .wider_example_1 {
    margin-top: 1em;
    margin-bottom: 5em; } }

article.assignment-content .bronte-styles .spf-rce-stage .wider_example_1 h2::before {
  content: "";
  position: absolute;
  background-color: #CC0300;
  height: calc(230%);
  width: 3px;
  top: 0;
  left: -80px; }

.wider_example_1 h2::after {
  content: "";
  position: absolute;
  background-color: #CC0300;
  height: 3px;
  width: calc(100% + 80px);
  top: 0;
  left: -80px; }

.wider_example_1 h2 {
  font-size: 2em;
  line-height: 1.15em;
  margin-top: 0.5em;
  padding-top: 1em;
  color: #CC0300;
  left: 0; }

.wider_example_1 .spf-rce-row:last-child::after {
  content: "";
  position: absolute;
  background: #CC0300;
  height: 3px;
  width: 97%;
  left: 0;
  bottom: -20px;
  margin-left: 10px; }

  @container (width < 1300px) {
  .wider_example_1 .spf-rce-section-content {
    width: 1150px; } }

    @container (width < 1200px) {
  .wider_example_1 .spf-rce-section-content {
    width: 950px; } }

    @container (width < 960px) {
  .wider_example_1 h2::before {
    display: none; } 
  .wider_example_1 h2::after {
    content: "";
    position: absolute;
    background-color: #CC0300;
    height: 3px;
    width: 100%;
    top: 0;
    left: 0; } }

@container (width < 640px) {
  .wider_example_1 h2 {
    font-size: 1.5em;
    margin-bottom: 0em; } }

/* Wider than Text Worked Example 2 */
.spf-rce-section.aside-group.wider_example_2 {
  background-color: #f8f9fa;
  max-width: 1280px;
  margin-top: 3em;
  margin-bottom: 3em;
  padding-bottom: 2em; }

.aside-group.wider_example_2 .spf-rce-section-content {
  background-color: #f8f9fa;
  margin: 0;
  padding-bottom: 2em;
  width: 1200px; }

article.assignment-content .spf-rce-section.aside-group.wider_example_2 h2:first-child {
  font-weight: bold;
  border-radius: none;
  margin-bottom: 2em;
  padding-bottom: 5px; }

article.assignment-content .spf-rce-section.aside-group.wider_example_2 h2 {
  font-size: 1.5rem;
  text-transform: none;
  margin: 0;
  margin-top: 10px;
  left: -25px;
  top: 10px;
  background: transparent;
  padding-left: 49px;
  padding-top: 1em;
  z-index: 4; }

article.assignment-content .spf-rce-section.aside-group.wider_example_2 h2::after {
  content: "";
  position: absolute;
  background-color: #e2e5ea;
  left: -63px;
  height: calc(100% + 60px);
  top: -20px;
  z-index: -1;
  width: 1280px; }

article.assignment-content .aside-group.wider_example_2 h2::before {
  display: none; }

article.assignment-content .aside-group.wider_example_2 h2 .label {
  color: #CC0300; }

article.assignment-content .aside-group.wider_example_2 h3 {
  font-size: 1.25rem;
  font-weight: 800;
  text-transform: none;
  margin-bottom: 2em;
  margin-top: 1em;
  left: -80px;
  background: transparent;
  padding: 0.15em 49px 0.15em;
  z-index: 8; }

article.assignment-content .aside-group.wider_example_2 h3::after {
  content: "";
  position: absolute;
  background-color: #e2e5ea;
  left: -64px;
  height: calc(100% + 16px);
  top: -12px;
  z-index: -1;
  width: 1280px;
  padding-bottom: 10px; }

article.assignment-content .stage-container .aside-group.wider_example_2 h3 span.number.spf-inline-span::after {
  content: "\A";
  white-space: pre; }

article.assignment-content .stage-container .aside-group.wider_example_2 h3::before {
  display: none; }

.aside-group.wider_example_2 figcaption p {
  margin-top: 1em; }

.aside-group.wider_example_2 figure {
  /* margin-top: 1em; */ }

.aside-group.wider_example_2 .spf-rce-section-content {
  background-color: transparent;
  margin: 0;
  padding-bottom: 2em; }

.aside-group.wider_example_2 .spf-rce-section-content .spf-rce-element h2 {
  margin: 0 0 0 -27px; }

.aside-group.wider_example_2 .spf-rce-section-content .spf-rce-element h3 {
  margin: 2em 0 2em 30px; }

  @container (width < 1200px) {
  .aside-group.wider_example_2 .spf-rce-section-background-wrapper .spf-rce-section-content {
    width: 930px !important; } 
  article.assignment-content .spf-rce-section.aside-group.wider_example_2 .spf-rce-section-background-wrapper h2::after {
    content: "";
    position: absolute;
    background-color: #e2e5ea;
    left: -110px;
    height: calc(100% + 60px);
    top: -20px;
    z-index: -1;
    width: 1200px; } 
  article.assignment-content .aside-group.wider_example_2 .spf-rce-section-background-wrapper h3::after {
    content: "";
    position: absolute;
    background-color: #e2e5ea;
    left: -110px;
    height: calc(100% + 16px);
    top: -12px;
    z-index: -1;
    width: 1200px;
    padding-bottom: 10px; } }

.spf-rce-section iframe {
  padding-left: 10px;
  max-width: 800px; }

.divVideo {
  padding-left: 10px;
  max-width: 800px; }

.grey-background {
  box-sizing: content-box;
  color: #000;
  background-color: #E3E3E3;
  padding: .5em; }

.spf-rce-section-content .spf-rce-element.remove-screen-gap {
  margin-bottom: -68px;
  padding-bottom: 0px; }

.math-align {
  vertical-align: top !important;
  top: -0.8em;
  position: relative; }

svg {
  vertical-align: middle !important; }

h4.glossaryTerm {
  padding-bottom: 0.3em; }

.remove-bottom-padding {
  padding-bottom: 0 !important; }

.remove-top-padding {
  padding-top: 0 !important; }

.spf-inline-expression {
  color: unset; }

  /* Added styles ----*/
  .xln-content {
    margin-bottom: 2rem;
  }

  .layout {
    display: block;
  } 
  .layout::after {
    content: "";
    display: table;
    clear: both;
  }

  .layout div.twoThirdsLeft {
    width: 65%;
    float: left;
    padding: 4px 15px 4px 0;
  }

.layout div.oneThirdRight {
  width: 35%;
  float: right;
  padding: 4px 0 4px 15px
}

.mcq-container button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  -webkit-padding: 0.4em 0;
  padding: 0.4em;
  margin: 0 0 0.5em 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.mcq-container {
  text-align: left;
  padding: 0;
  max-width: 260px;
  margin: 0 auto;
  color: var(--text-color);
  border: none;
  font-family: var(--body-font);
  font-size: 16px;
  line-height: 18px;
  padding: 8px;
  min-width: 100%;
}

@container (width < 640px) {
 .bronte-styles fieldset.mcq-container {
    max-width: none;
    padding: 0px 40px;
  }
}


/* Legend elements */
.component-label-container {
  color: inherit;
  margin-bottom: 15px;
}

.component-title, .component-label {
  display: inline-block;
}

.component-instructions {
  margin-top: 15px;
}

.question-prompt {
  margin-bottom: 30px;
  font-family: var(--title-font);
}


/* Answer section */
div.answer {
  margin: 15px 0;
  display: grid;
  grid-template-columns: 40px auto;
  color: var(--text-color);
}

.answer .pearson-component, .answer details {
  grid-column: 2;
}

/* selector inputs */
input.selector {
  -webkit-appearance: none;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 0 1px var(--text-color);
  margin-top: 10px;
}

input.selector:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
  cursor: pointer;
}

input.selector:checked {
  padding: 2px;
}

input.selector:checked:before {
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--primary-color);
}

input.selector:not(:checked):not(.locked):hover, input.selector:not(.locked):focus[type="radio"] {
  cursor: pointer;
  outline: 2px solid;
  outline-offset: 0;
}

input.selector:not(.locked):focus[type="checkbox"] {
  outline: 2px solid var(--primary-color);
  outline-offset: 0;
}

input.selector.checkbox {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input.selector.checkbox:before {
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

input.selector.checkbox:not(:checked):not(.locked):hover {
  border-radius: 2px;
}

input.selector.checkbox:checked {
  box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 10px var(--primary-color);
  border-color: transparent;
}

input.selector.checkbox:checked:before {
  width: 9px;
  height: 9px;
  background-color: #fff;
}

input.selector, div.feedback-icon {
  width: 20px;
  height: 20px;
  grid-column: 1;
  align-self: flex-start;
}

input.selector.hide {
  display: none;
}

div.feedback-icon {
  margin-top: 5px;
}


label.option:not(.locked) {
  grid-column: 2;
  cursor: pointer;
  align-self: center;
}

label.option p {
  line-height: 3.25;
  display: inline;
}
.mcq-container button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: white;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer
}
  /* Responses*/
  
  /* OFF state */
  .bronte-styles .response {
    grid-column: 2;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: none;
  }
  
  @container (width < 640px) {
    .bronte-styles  .response {
      grid-column: 1/span 2;
    }
  }
  
  /* ON state */
  .bronte-styles .response.display {
    display: block;
  }
  
  .bronte-styles .response-header {
    margin-bottom: 15px;
    grid-column: 2;
    font-weight: bold;
    size: 40px;
  }
  
  
  /* colors */
  .bronte-styles  .response.correct {
    background-color: var(--success-color-10);
  }
  
  .bronte-styles .response.correct .response-header {
    color: var(--success-color);
  }
  
  .bronte-styles  .response.incorrect {
    background-color: var(--error-color);
  }
  
  .bronte-styles  .response.incorrect .response-header {
    color: var(--error-color);
  }
  
  
  /* Feedback Icons*/
  .bronte-styles .feedback-icon {
    border-radius: 10px;
  }
  
  .bronte-styles .feedback-icon.correct {
    background-color: var(--success-color);
  }
  
  .bronte-styles  .feedback-icon.incorrect {
    background-color: var(--error-color);
  }
  
  .bronte-styles  .feedback-icon.hide {
    display: none;
  }
  
  .bronte-styles .feedback-icon.display {
    display: block;
  }
  .mcq-container {
    --body-font: system-ui;
    --title-font: system-ui;
    --primary-color: rgba(0, 115, 202, 1);
    --primary-color-40: rgba(0, 115, 202, 0.4);
    --primary-color-70: rgba(0, 115, 202, 0.7);
    --text-color: rgba(0, 0, 0, 0.7);
    --success-color: rgba(74, 211, 172, 1);
    --success-color-10: rgba(74, 211, 172, 0.1);
    --error-color: #CC0300;
    --error-color-10: #E50B69;
    --surface-color: rgba(255, 255, 255, 1);
    --background-color: rgba(255, 255, 255, 1);
  }