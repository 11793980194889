/* Switch/Toggle */

$color-theme-ref-neutral-neutral70: #a9aca8ff;
$color-theme-sys-light-secondary: #5066c5ff;

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $color-theme-ref-neutral-neutral70 url(../images/icon_cross.png) no-repeat 28px center;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;

        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            top: 3px;
            left: 4px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }

    input { 
        opacity: 0;
        width: 0;
        height: 0;
    
        &:checked {
            + .slider {
                background: $color-theme-sys-light-secondary url(../images/icon_check.png) no-repeat 9px center;

                &:before {
                    -webkit-transform: translateX(24px);
                    -ms-transform: translateX(24px);
                    transform: translateX(24px);
                }
            }
        }
        
        &:focus { 
            + .slider {
                box-shadow: 0 0 1px #047A9C;
            }
        }

        &:disabled, &[disabled] {
            + .slider {
                background-color: #C2C7CC;
            }
        }
    }
}


.mode-toggle {
    display:flex;
    flex-direction: column;
    align-items: center;
    background: rgba(117, 117, 117, 0.2);
    border-radius: 4px;
    position:relative;
    border: 1px solid #ccc;
    padding:0;


    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width:40px;
        height:40px;
        position: relative;
        z-index: 1;
    }
    &:before {
        content:'';
        z-index: 0;
        position:absolute;
        display:block;
        width:36px;
        height:36px;
        left:2px;
        bottom:2px;
        background:#F9F9F9;
        border-radius:4px;
        transition:transform 0.3s ease;
    }

    &[aria-pressed=true] {
        &:before {
            transform:translateY(-40px);
        }
    }

}