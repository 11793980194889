.modal {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.36);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;

    .modal-content {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
        background-color: #ffffff;
        z-index: 3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 600px;
        max-width: 100%;
        outline: none;
        border-radius: 2px;
        padding: 2rem;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        label {
            color: #6a7070;
            font-size: 0.875rem;
            font-weight: 600;
        }
    }

    .select-container {
        position: relative;

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: block;
            margin: 0.25rem 0;
            padding: 0 0.75rem;
            padding-right: 2.625rem;
            height: 2.25rem;
            color: #252525;
            border: 1px solid #C7C7C7;
            background: #ffffff;
            border-radius: 4px;
            font-size: 0.875rem;
            width: 100%;
            max-width: 100%;
        }
        
        svg {
            position: absolute;
            right: 0.75rem;
            top: calc(50% - 9px);
            fill: #6A7070;
        }
    }
}