.search-content {
    display: flex;
    flex-direction: column;
   overflow: hidden;

    .search-results, .search-loading {
        padding: 20px;
        padding-right:0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow-x: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .search-default {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        overflow-x: hidden;
        overflow-y: auto;

        header {
            margin-bottom: 16px;
        }
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            color: #262626;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5;
            letter-spacing: 0.16px;
            margin: 0;
        }

        a {
            font-size: 14px;
            color: #70a5e2;
        }
    }

    form {
        display: flex;
    }
    input {
        display: block;
        border-radius: 6px;
        border: 1px solid #F0F2F5;
        background: #FFF url(../images/search-lg.svg) no-repeat 12px center;
        box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.12);
        min-height: 39px;
        padding: 16px 12px 16px 48px;
        margin: 20px 20px 0;
        display: flex;
        align-items: center;
        flex: 1;

        #search-clear {
            background-color: #E8ECEF;
            border-radius: 50%;
        }
    }

    .search-glossary {
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, .08);
        box-shadow: 0px 2px 4px 0px rgba(25, 33, 61, 0.08);
        padding: 12px 16px;
        background-color: rgba(0, 0, 0, .05);
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-right: 20px;

        p {
           color:  #19213DD6;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
    }

    .search-etextbook {
        margin-right: 20px;
        .content {
            margin-top: 9px;
            display: flex;
            gap: 16px;

            span {
                flex: 1 0 100px;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                width: 100%;

                li {
                    padding: 6px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                    font-size: 14px;
                    color: #19213D;
                    font-weight: 400;
                    line-height: 20px;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    display: -webkit-box;

                    &:first-child {
                        padding-top: 0;
                    }
                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .search-media {
        header {
            margin-right: 20px;
        }
        .media {
            list-style-type: none;
            margin-top: 9px;
            display: flex;
            gap: 8px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            white-space: nowrap;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                display: flex;
                flex-direction: column;
                gap: 8px;
                flex: 1;

                &.card {
                    padding: 0 !important;
                }

                img {
                    max-height: 98px;
                    width: auto;
                }

                p {
                    overflow: hidden;
                    color: #666;
                    text-overflow: ellipsis;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */   
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    padding: 0 8px;
                    
                }
            }
        }
    }

    .card {
        display: flex;
        padding: 14px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        border-radius: 6px;
        border: 1px solid #F0F2F5;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(25, 33, 61, 0.08);
    }

    .search-tools {
        ul {
            list-style-type: none;
            display: flex;
            gap: 12px;

            li {
                p {
                    color: #1E1E1E;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 21px */
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    display: -webkit-box;

                    strong {
                        display: block;
                        color: #19213D;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 133.333%;
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }

    /* Search default content */
    .search-previous {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0 0;

            li {
                display: flex;
                gap: 16px;
                align-items: center;
                justify-content: space-between;
                padding: 8px 0;
                border-bottom: 1px solid #ECF1F6;

                p {
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    color:  #1F2C37;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 180%; /* 25.2px */
                }

                svg {
                    fill: transparent;
                }
            }
        }

        .icon-btn {
            background-color: transparent;
            border: 0;
            padding: 0;
        }
    }

    .search-tending {
        > div {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            span {
                border-radius: 8px;
                display: flex;
                padding: 8px 16px;
                justify-content: center;
                align-items: center;
                background: #E4EEF9;
                color:#1F2C37;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 21px */
            }
        }
    }
    
    .search-recent {
        ul {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            li {
                justify-content: center;
                align-items: center;
                height: 66px;
                flex: 0 1 48%;
                overflow: hidden;
                color: #1F2C37;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 128.571% */
                text-transform: capitalize;
                text-align: center;
            }
        }
    }
}

.skeleton {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
    width: 100%;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
        );
        animation: shimmer 5s infinite;
        content: '';
    }

    &[variant=circular] {
        border-radius: 50%;
    }

    &[variant=text] {
        border-radius: 6px;
    }
}

@keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
}
