.etext {
    display: block;
    width: 100vw;
    height: 100vh;
    background: #DDDEDF;
    position: relative;

    .etext__navbar {
        position: absolute;
        left: 0;
        width: 5.875rem;
        height: 100vh;
        background: #F7F9FD;
        box-shadow: 0px 1px 4px rgba(28, 34, 46, 0.05), 0px 2px 12px rgba(28, 34, 46, 0.06);
        border-radius: 0px 16px 16px 0px;
        padding: 2rem 1.5rem;        
        z-index: 10;

        > ul {
            margin: 2rem 0 0 0;
            list-style-type: none;

            li {
                margin-bottom: 1rem;
            }
        }

        .btn-close {
            bottom: 2rem;
            left: 1.5rem;
            position: absolute;
        }
    }

    .etext__header {
        position: fixed;
        top: 0;
        height: 4rem;
        width: 100vw;
        z-index: 5;
        /*grid-column: 2;
        grid-row: 1; */
        background: linear-gradient(180deg, #F6F9FD 78.12%, rgba(247, 249, 253, 0) 100%);
        display: flex;
        justify-content: flex-end;
        padding: 0.5rem 1.5rem;

        h1 {
            font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .user-id {
            background: #F6F9FD;
            border: 1px solid #ECF1FB;
            border-radius: 20px;    
            width: 2.5rem;
            height: 2.5rem;
            font-style: normal;
            font-weight: 700;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
    }

    .etext__body {
        height: 100vh;
        padding-left: 5.875rem;
        overflow: auto;
        
        .page {
            max-width: 64rem;
            background: #fff;
            margin: 0 auto;
            padding: 5rem 5rem 6rem;
        }
    }

    .etext__footer {
        /*grid-column: 2;
        grid-row: 3;*/
        position:fixed;
        bottom: 0;
        width: 100vw;
        height: 5.5rem;
        background: linear-gradient(180deg, #F6F9FD 38.64%, rgba(221, 222, 223, 0) 100%);
        transform: rotate(180deg);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 0.5rem;

        img {
            transform: rotate(180deg);
        }
    }
}