.gr-radio {
    margin-bottom: 1.25rem;
    min-height: 1.5rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    
    input[type=radio] {
        opacity: 0;
        position: absolute;
    
        &:focus {
            ~ span {
                outline: 0;
            }
        }

        + label {
            display: inline-block;
            vertical-align: middle;
            line-height: 18px;
            padding-left: 36px;
            font-size: 1rem;
            cursor: pointer;
        }

        ~ span {
            -webkit-box-sizing: content-box;
            border: 2px solid #c7c7c7;
            background: white;
            border-radius: 50%;
            box-sizing: border-box;
            color: #6a7070;
            display: block;
            height: 24px;
            left: 0;
            padding: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 24px;
        
            svg {
                opacity: 0;
            }
        }

        &:checked {
            ~ span { 
                border-color: transparent;

                svg.select-icon {
                    opacity: 1;
                    top: -2px;
                    position: absolute;
                    left: -2px;
                    fill: #047A9C;
                }
            }
           
        }

        &:disabled, &:checked:disabled {
            ~ span {
                border-color: #ddd;
                background-color: #f7f7f7 ;

                svg.select-icon {
                    fill: #ccc;
                }
            }
        } 
    }

    &.selected {
        input[type=radio] {
            &:checked {
                ~ span {
                    svg.correct-icon {
                        opacity: 1;
                        top: -8px;
                        position: relative;
                        left: -1px;
                        fill: #038238;
                    }
                    
                    svg.select-icon {
                        display: none;
                    }
                }
            }
        }
    }
}


.chatbot-drawer .drawer-body .response .message .chat-mcq {
    fieldset {border:0;padding:0;margin:0;}
    legend {
        margin-bottom:1em;
    }
    .option {
        padding: 0.5em;
        margin-bottom:0.5rem;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid rgba(35, 136, 255, 0.20);
        box-shadow: 0px 2px 12px 0px rgba(25, 33, 61, 0.06);

        &:hover {
            border: 1px solid #5385BF;
            box-shadow: 0px 8px 15px 0px rgba(25, 33, 61, 0.20);
        }
    }
    .gr-radio {
        margin:0;
        &.correct {
            input[type=radio] {
                ~ span svg {
                    opacity: 1;
                        top: -2px;
                        position: absolute;
                        left: -2px;
                        fill: #038238;
                }
            }
        }
        &.incorrect input[type=radio] {
            ~ span svg {
                opacity: 1;
                top: -2px;
                position: absolute;
                left: -2px;
                fill: #CC0300;
            }
        }

    }
    .feedback.option-feedback {
        background-color: #c7c7c766; 
        border-radius: 8px;
        padding: 12px 16px;
        /*background: #F0F2F5;*/
        margin-bottom: 8px;
        margin-top: 24px;
        p {margin:0;}
    }
}