.overview-body {
    padding: 2rem 0;
    //background-color: #F5F5F5;

    .wrapper {
        display: flex;
        justify-content: space-between;

        @include devices(medium) {
            flex-direction: column-reverse;
            gap: 2rem;
        }
        
    }

    .overview-body__left {
        flex: 0 0 52%;
    }

    .overview-body__right {
        flex: 0 0 40%;
    }    
}

.svg-item {
    position: relative;
    max-width: 8.25rem;
    animation: donutfade 1s;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));

    @include devices(large) {
        max-width: 6.25rem;
    }
    
    @include devices(medium) {
        max-width: 9.25rem;
    }
    
    @include devices(small) {
        max-width: 6.25rem;
    }


    @keyframes donutfade {
        /* this applies to the whole svg item wrapper */
        0% {
            opacity: .2;
        }
        100% {
            opacity: 1;
        }
    }

    .donut-ring {
        stroke: #fff;
        stroke-width: 1rem;

        @include devices(small) {
            stroke-width: 0.875rem;
        }
    }
    
    .donut-segment {
        transform-origin: center;
        animation: donut1 1s;  
        stroke: #007FA3;
    }

    .center-text {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .large {
            font-weight: 400;
            font-size: 2rem;
        }
    }
    
    @keyframes donut1 {
        0% {
            stroke-dasharray: 0, 100;
        }
        100% {
            stroke-dasharray: 236, 10;
        }
    }
}

.assignment-objectives {
    h4 {
        font-size: 1.125rem;
        font-weight: 600;     
        line-height: 1.5;   
        margin-bottom: 0.5rem;
    }

    ul {
        margin-left: 1rem;
        padding-left: 0.5rem;

        @include devices(medium) {
            margin-left: 1rem !important;
        }

        li {
            line-height: 1.5;
            font-weight: 400;
            letter-spacing: 0.15px;
            margin-bottom: 0.25rem;
        }
    }
}

ul.assignment-summary {
    list-style-type: none;
    margin: 0 0 2.5rem 0 !important;
    padding: 0;

    @include devices(medium) {
        margin:  0 0 1.5rem 0 !important;
    }

    li {
        padding: 0.5rem;
        text-transform: capitalize;
        letter-spacing: 0.15px;

        &:last-child {
            border: 0;
        }

        &:before {
            content: "";
            display: inline-block;
            align-items: center;
            width: 1rem;
            height: 1rem;
            margin-right: 0.75rem;
            border-radius: 50%;
        }

        &.activities {
            &:before {
                background-color: #C45303;
            }
        }

        &.readings {
            &:before {
                background: #566BB7;
            }
        }
        &.videos {
            &:before {
                background: #647A00;
            }
        }
        &.questions {
            &:before {
                background: #753299;
            }
        }
        &.others {
            &::before {
                background: #297F94;
            }
        }
    }
}

.back-to-top {
    background: rgba(0, 127, 163, 0.5);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 4rem;
    right: 4.5rem;
    border: 0;
    cursor: pointer;

    @include devices(small) {
        bottom: 2rem;
    }

    &:hover {
        background: rgba(0, 127, 163, 1);
    }
}