.global-footer {
    margin: 0;
    //background: #F5F5F5;

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
        line-height: 2;
        gap: 1rem;
        padding: 0.5rem 0;

        @include devices(medium) {
            flex-direction: column;
            padding: 0.5rem 1rem;
        }
    }
    
    
    p {
        margin: 0;
    }

    a {
        color: #444;
    }
    
}