.gr-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #6A7070;
    background: transparent;
    color: #6A7070;
    padding: 8px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 22px;
    position: relative;
    margin: 12px;
    line-height: 1.15;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        color: #252525;
        border: 1px solid #252525;
    }

    &:focus {
        outline: 1px solid #6A7070;
        box-shadow: none;
    }

    &.primary {
        background: #AE367E /*linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)*/;
        border: 1px solid #AE367E;
        color: white;
    
        &:hover {
            color: white;
            background: #8C2B66/*linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)*/;
        }

        &:focus {
            outline: 1px dashed #eee;
            outline-offset: -4px;
            box-shadow: none;
        }

        &:disabled {
            background: #AE367E;
            border: 1px solid #AE367E;
            opacity: 0.5;
            color: white !important;
        }

        &.next {
            // background-color: #007FA3;
            // border-color:#007FA3;
            // color:#fff;
            // box-shadow: 0px 2px 4px 0px rgba(30, 73, 108, 0.20), 0px 4px 8px 0px rgba(4, 122, 156, 0.20);
            background: linear-gradient(86.65deg, #1ce6b5 -81.59%, #2128d9);
            border:none;
            padding:0;
            color: #fff;
            opacity: .8;
            display:flex;
            align-items: center;
            gap:8px;

            svg {fill:currentColor;}
            &:hover, &:focus {
                opacity: 1;
            }
        }
    }

    &.attention {
        background-color: #252525;
        border: 1px solid #252525;
        color: #fff;
    
        &:hover {
            background-color: #000000;
        }

        &:focus {
            box-shadow: none;
            outline: 1px dashed #eee;
            outline-offset: -4px;
        }
    }

    
    &.small {
        min-width: 128px;
        padding: 7px 20px;
        font-size: 14px;
    
        &:focus {
            &:after {
                padding: 18px 21px;
            }
        }
    }

    &.extra-large {
        min-width: 152px;
        padding: 10px 24px;
        font-size: 16px;

        &:focus {
            &:after {
                padding: 22px 21px;
                border-radius: 24px;
            }
        }
    }
    
    &:disabled {
        background-color: #E9E9E9;
        color: #6A7070 !important;
        border: 0;
        cursor: default !important;
    }

    &.icon-btn-24 {
        min-width: auto;
        padding: 0.5rem 0.75rem;
        border: 0;
        margin: 0;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;

        &:focus {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }

        &:hover {      
            border-radius: 50%;
            box-shadow: none;      
            background-color: #f2f2f2;
        }
        
        &:active, .active {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }
        
        svg {
            fill: #6A7070;
        }

        &:disabled {
            background-color: transparent !important;

            svg {
                fill: #ccc;
            }
        }
    }
    
    &.icon-btn-18 {
        min-width: auto;
        padding: 0.5rem 0.75rem;
        border: 0;
        margin: 0;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;

        &:focus {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }
    
        &:hover {      
            border-radius: 50%;
            box-shadow: none;      
            background-color: #f2f2f2;
        }
        
        &:active, .active {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }

        &:disabled {
            background-color: transparent !important;

            svg {
                fill: #ccc;
            }
        }

        svg {
            fill: #6A7070;
        }
    }

    &.no-border {
        border: 0;
    
        &:hover {
            border: 0;
        }

        &:focus {
            &:after {
                border-radius: 5px;
                min-width: auto;
                left: auto;
                top: auto;
                padding: 16px;
            }
        }
    }

    &.link-btn {
        border: 0;
        padding: 0;
        min-width: 0;
        color: #05112A;

        &:focus, &:active {
            box-shadow: none;
            outline: none;
        }
    }
}