@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


* {
    font-size: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;    
}


h1, h2, h3, h4, h5, p, ul, li {
    margin: 0;
    padding: 0;
    color: #05112A;
}

h1 {
    font-size: 1.5rem;
    font-weight: 400;
}
h2 {
    font-size: 1.25rem;
    font-weight: 600;
}
h3 {
    color: #3E4C59;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

h4 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5;

    &[role=button] {
        cursor: pointer;
    }
}

h5 {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.2;
}

h6 {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.2;
    margin: 1rem 0 0 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #333;
}

a {
    text-decoration: none;
    color: #007FA3;
}

#pe-icons-sprite {
    display: none;
}

svg {
    fill: currentColor;
}

.icon-18 {
    width: 18px;
    height: 18px;
}
.icon-24 {
    width: 24px;
    height: 24px;
}

.main {
    display: flex;
    max-width: 100%;
    align-items: stretch;

}

.wrapper {
    max-width: 80rem;
    padding: 0 4.5rem;
    margin: 0 auto;

    @include devices(medium) {
        padding: 0 1.25rem;
    }

    @include devices(small) {
        padding: 0 1rem;
    }
}


/* Input ----*/
.gr-input {
    display: block;
    margin: 4px 0;
    padding: 0 12px;
    height: 36px;
    color: #252525;
    border: 1px solid #9AA5B1;
    background: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100%;

    &:focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
        box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
    }

    &:disabled {
        color: #C7C7C7;
        background-color: #E9E9E9;
    }

    &.search-input {
        border-radius: 99px;
        background: url(../images/icon_search.png) no-repeat 0.625rem center;
        padding-left: 2.5rem;
        font-size: 1rem;
        min-width: 19rem;
    }
}

.sr-only {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

/* Checkbox ---*/
.gr-checkbox {
    position: relative;
    input[type='checkbox'] {
        opacity: 0;
        position: absolute;
        cursor: pointer;
    
        ~ label {
            display: inline-block;
            line-height: 1.5;
            min-height: 24px;
            padding-left: 2.5em;
            position: relative;
            z-index: 2;
            cursor: pointer;
            font-size: 14px;
        }

        ~ span {
            height: 22px;
            line-height: 1.5;
            text-align: center;
            width: 22px;
            border: 2px solid #c7c7c7;
            background: white;
            border-radius: 2px;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 0;

            svg {
                height: 24px;
                opacity: 0;
                width: 24px;
                top: -3px;
                position: relative;
                left: -3px;
                fill: #047A9C;
            }
        }

        &:disabled {
            ~ span {
                height: 22px;
                line-height: 1.5;
                text-align: center;
                width: 22px;
                border: 2px solid #c7c7c7;
                border-radius: 2px;
                left: 0;
                position: absolute;
                top: 0;
                z-index: 0;
                background-color: #E9E9E9;
                
                svg {
                    fill: #C7C7C7;
                }
            }
        }

        &:focus {
            ~ span {
                outline: 0;
                -webkit-box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
                box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
            }
        }

        &:checked {
            ~ span {
                svg {
                    opacity: 1;
                }
            }
        }
    }
}

/* Breadcrumbs -----*/
.breadcrumnbs {
    display: flex;
    gap: 1.125rem;
    margin: 1rem 0 1.5rem;

    a {
        color: #3E4C59;
        text-decoration: none;

        &::after {
            content: "";
            width: 8px;
            height: 14px;
            display: inline-block;
            background: url(../images/icon_breadcrumb.png) no-repeat center center;
            margin-left: 1.125rem;
        }

        &:last-child {
            color: #007FA3;

            &::after {
                display: none;
            }
        }

        &:hover {
            color: #007FA3;
        }
    }
}

.badge {
    display: inline-block;
    background: #CFD8DC;
    border-radius: 99px;  
    font-weight: 400;
    font-size: 0.75rem;
    color: #263238;
    padding: 2px 6px;
    margin-left: 0.5rem;
}
