@mixin devices ($breakpoint) { //the name of the mixin is devices
    @if $breakpoint == large {    
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }
  
    @if $breakpoint == medium {    
        @media only screen and (max-width: 768px)  {
            @content;
        }
    }
 
    @if $breakpoint == small {
        @media only screen and (max-width: 480px) {
            @content;
        }
    }
}